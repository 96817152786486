import React, { useEffect, useState } from 'react'
import { useRoutes, Navigate, useLocation } from 'react-router-dom'

import AdminSearches from './components/Admin/Searches/SearchIndex'
import SearchSettings from './components/Admin/Searches/ScraperSettings/SearchSettings'
import AdminUsers from './components/Admin/AdminUsers'
import Analytics from './components/Analytics/AnalyticsMain.js'
import Loadboard from './components/Loadboard/LoadBoardMain.js'
import Login from './components/AuthPages/Login.js'
import SignUp from './components/AuthPages/SignUp.js'
// import Extension from './components/LandingPage/Extension/Extension.js'
import Policy from './components/Policies/Policy.js'

import Settings from './components/Settings/SettingsMain.js'
import PaymentPage from './components/Payment/Payment.js'
import LandinPage from './components/LandingPage/LandingPage.js'
import UninstallFeedback from './components/UninstallExtention/UninstallFeedback.js'
import PasswordResetRequest from './components/AuthPages/PasswordResetRequest.js'
import PasswordReset from './components/AuthPages/PasswordReset.js'
import LoginPrompt from './components/AuthPages/LoginPromptExtension.js'

import auth from './services/authService'

function ProtectedRoute({ path, condition, element }) {
	const location = useLocation()

	// return condition ? element : <Navigate to="/account/login" />
	return condition ? (
		element
	) : (
		<Navigate to={`/account/login${location.search}`} />
	)
}

function AuthRedirect({ path, condition, element }) {
	const location = useLocation()

	// return condition ? <Navigate to="/loadboard" replace /> : element
	return condition ? (
		<Navigate to={`/loadboard${location.search}`} replace />
	) : (
		element
	)
}

function Routes() {
	const user = auth.getCurrentUser()
	const isAdmin = user?.isAdmin
	const location = useLocation()

	const [sourceExtension, setSourceExtension] = useState(false)
	const [showLoginPrompt, setShowLoginPrompt] = useState(false)
	const [token, setToken] = useState('')

	useEffect(() => {
		const params = new URLSearchParams(location.search)

		if (params.get('source') === 'extension') {
			if (user) setShowLoginPrompt(true)

			const token = params.get('token')
			setToken(token)

			setSourceExtension(true)
		}
	}, [location.search])

	const routes = useRoutes([
		{
			path: '/',
			element: <AuthRedirect condition={user} element={<LandinPage />} />,
		},
		{
			path: '/account/login',
			element: showLoginPrompt ? (
				<Navigate to="/login-prompt" />
			) : (
				<AuthRedirect
					condition={user && !sourceExtension}
					element={
						<Login
							sourceExtension={sourceExtension}
							setSourceExtension={setSourceExtension}
							windowOpender={window.opener}
							userToken={token}
						/>
					}
				/>
			),
		},
		{
			path: '/login-prompt',
			element: (
				<LoginPrompt userToken={token} sourceExtension={sourceExtension} />
			),
		},

		{
			path: '/account/signup',
			element: (
				<AuthRedirect
					condition={user}
					element={
						<SignUp
							sourceExtension={sourceExtension}
							setSourceExtension={setSourceExtension}
							userToken={token || null}
						/>
					}
				/>
			),
		},
		{
			path: '/policy',
			element: <Policy />,
		},
		{
			path: '/uninstall-feedback',
			element: <UninstallFeedback />,
		},
		{
			path: '/password-reset-request',
			element: (
				<PasswordResetRequest
					sourceExtension={sourceExtension}
					userToken={token}
				/>
			),
		},
		{
			path: '/password-reset/:token',
			element: <PasswordReset />,
		},
		// {
		// 	path: '/loadboard',
		// 	element: <ProtectedRoute condition={user} element={<Loadboard />} />,
		// },
		{
			path: '/loadboard',
			element:
				user && sourceExtension ? (
					<Navigate to={`/login-prompt`} />
				) : (
					<ProtectedRoute condition={user} element={<Loadboard />} />
				),
		},
		{
			path: '/payment',
			element: <ProtectedRoute condition={user} element={<PaymentPage />} />,
		},
		{
			path: '/settings',
			element: <ProtectedRoute condition={user} element={<Settings />} />,
		},
		{
			path: '/analytics',
			element: <ProtectedRoute condition={user} element={<Analytics />} />,
		},
		{
			path: '/admin/searches',
			element: (
				<ProtectedRoute
					condition={user && isAdmin}
					element={<AdminSearches />}
				/>
			),
		},
		{
			path: '/admin/users',
			element: (
				<ProtectedRoute condition={user && isAdmin} element={<AdminUsers />} />
			),
		},
		{
			path: '/admin/searchsettings',
			element: (
				<ProtectedRoute
					condition={user && isAdmin}
					element={<SearchSettings />}
				/>
			),
		},
		{
			path: '*',
			element: user ? (
				<Navigate to="/loadboard" replace />
			) : (
				<Navigate to="/" replace />
			),
		},
	])

	return routes
}

export default Routes
