import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Axios from 'axios'

import { getCurrentUser } from '../../../services/authService'
import { baseURL } from '../../../config'
import styles from './UserSearches.module.css'

import Toast from './Toast'

function UserNewSearch({ isModalOpen, closeModal, filters }) {
	const inputField = `${filters?.pickupCity}, ${filters?.pickupState}`

	const [loading, setLoading] = useState(false)
	const [searchName, setSearchName] = useState(inputField)
	const [error, setError] = useState('')
	const [bot, setBot] = useState(false)
	const [showToast, setShowToast] = useState(false)

	const userToken = localStorage.getItem('jwtToken')
	const [searchParams] = useSearchParams()
	const tg_id = searchParams.get('tg_id')

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	const handleTelegramConnect = () => {
		closeModal()

		const user = getCurrentUser()
		const link = user?.tgLink ? user?.tgLink : user?.id

		// window.open(
		// 	`https://t.me/test_loadfetcherbot?start=${link || ''}`,
		// 	'_blank'
		// )

		window.open(`https://t.me/loadfetcherbot?start=${link || ''}`, '_blank')
	}

	const checkUser = async () => {
		try {
			const res = await Axios.get(`${baseURL}/api/users/check`)

			setBot(res?.data?.bot_user_id)
		} catch (err) {
			setBot(false)
			console.log(err)
		}
	}

	useEffect(() => {
		if (tg_id && userToken) {
			async function callTgIdAssign() {
				let res
				try {
					res = await Axios.get(`${baseURL}/api/users/tg-id/assign/${tg_id}`, {
						headers: {
							authorization: userToken,
						},
					})

					setShowToast(true)

					setTimeout(() => {
						setShowToast(false)
					}, 3000)
				} catch (err) {
					console.log(err)
					setShowToast(false)
				}
			}

			callTgIdAssign()
		}
	}, [tg_id])

	useEffect(() => {
		if (!bot) checkUser()

		if (filters?.pickupCity || filters?.pickupState) {
			setSearchName(`${filters.pickupCity}, ${filters.pickupState}`)
		} else {
			setSearchName('Search Name')
		}
	}, [filters.pickupCity, filters.pickupState, isModalOpen])

	const createSearch = async () => {
		if (!searchName.trim()) {
			setError('Search name is required!')
			return
		}

		setError('')

		const user = getCurrentUser()

		const pickCoords = {
			lat: filters.pickupLat,
			lng: filters.pickupLng,
		}

		const dropCoords = filters.dropLat
			? {
					lat: filters.dropLat,
					lng: filters.dropLng,
			  }
			: null

		// const origin = filters?.pickupCity
		// 	? `${filters.pickupCity}, ${filters.pickupState}`
		// 	: ''

		const dest = filters.dropCity
			? `${filters.dropCity}, ${filters.dropState}`
			: ''

		const userToken = localStorage.getItem('jwtToken')
		const sources = filters?.sourceType?.map(s => s?.value)
		const defaultSources = ['TQL', 'DAT', 'COYOTE', 'JB HUNT', 'AMAZON']

		const pickUpInfo = {
			searchName: searchName,
			pickUpLocation: `${filters.pickupCity}, ${filters.pickupState}`,
			loadBoard: sources && sources.length ? sources : defaultSources,
			pickUpDeadhead: filters.pickupMaxDist,
			equipment:
				filters.equipType === 'Any' || !filters.equipType
					? null
					: filters.equipType,
			pickupCoordinates: pickCoords,
			destination: dest,
			dropOffCoordinates: dropCoords,
			dropOffDeadhead: filters.dropoffMaxDist,
			userId: user.id,
			is_bot: true,
			isNotification: true,
			ignoreLoadOuts: filters.ignoreLoadOut,
			weight: filters.weight,
			stopCount: filters.stopCount,
			distance: filters.distance,
			excludedBrokers: filters.excludeBroker?.map(br => br.value),
			excludeOrIncludeBrokers: filters.excludeOrIncludeBrokers,
			filters: filters,
		}

		try {
			setLoading(true)

			await Axios.post(`${baseURL}/api/new-search`, pickUpInfo, {
				headers: {
					authorization: userToken,
				},
			})

			closeModal()
		} catch (err) {
			closeModal()
			setLoading(false)
			console.log(err, 'err')

			const errorMessage =
				err?.response?.data?.msg || err?.response?.data?.message

			setShowToast(true)
			setError(errorMessage || 'Something went wrong!')

			setTimeout(() => {
				setShowToast(false)
			}, 3000)
		} finally {
			setLoading(false)
			closeModal()
		}
	}

	if (showToast && !error) {
		return (
			<>
				<Toast message="Connected to Telegram successfully!" />
			</>
		)
	} else if (showToast && error) {
		return (
			<>
				<Toast message={error} error={true} />
			</>
		)
	}

	if (!filters.pickupCity && !filters?.bobtail) {
		return (
			<>
				<dialog
					onClick={handleOutsideClick}
					className={`modal ${isModalOpen ? 'modal-open' : ''}`}
				>
					<form
						method="dialog"
						className={` modal-box ${styles['searches-modal-box-custom']}`}
					>
						<h2 className="font-bold text-lg text-amber-200">
							Oops! Origin field is required!
						</h2>
						<p className="text-lg pt-4 py-2 text-gray-200">
							A search without specifying the origin city lacks a defined
							starting point. Please enter the origin city before proceeding.
						</p>

						<div className="modal-action">
							<button onClick={closeModal} className="btn">
								Close
							</button>
						</div>
					</form>
				</dialog>
			</>
		)
	}

	if (!bot) {
		return (
			<>
				<dialog
					onClick={handleOutsideClick}
					className={`modal ${isModalOpen ? 'modal-open' : ''}`}
				>
					<form
						method="dialog"
						className={` modal-box ${styles['searches-modal-box-custom']}`}
					>
						<h2 className="font-bold text-lg text-amber-200">
							Action required!
						</h2>

						<p className="text-lg pt-4 py-2 text-gray-200">
							Please connect your telegram account with loadfetcher.
						</p>

						<div className="modal-action">
							<button onClick={closeModal} className="mr-4 btn">
								Close
							</button>

							<button
								onClick={handleTelegramConnect}
								className="mx-4 btn btn-success"
							>
								Connect telegram bot
							</button>
						</div>
					</form>
				</dialog>
			</>
		)
	}

	return (
		<div>
			{isModalOpen && (
				<dialog
					onClick={handleOutsideClick}
					className={`modal ${isModalOpen ? 'modal-open' : ''}`}
				>
					<form
						method="dialog"
						className={` modal-box ${styles['searches-modal-box-custom']}`}
						style={{ backgroundColor: '#1c2126' }}
					>
						<h3 className="font-bold text-lg">Save New Search</h3>

						<div className="form-control w-full my-4">
							{/* {error && <span className="text-red-500">{error}</span>} */}
							<input
								value={searchName}
								onChange={e => setSearchName(e.target.value)}
								type="text"
								placeholder="Name your search"
								className="input input-bordered w-full"
							/>
						</div>

						<div className="modal-action">
							<button onClick={closeModal} className="btn mr-6">
								Close
							</button>

							<button
								onClick={createSearch}
								className={`btn btn-success ${loading ? 'btn-disabled' : ''}`}
								tabIndex={loading ? '-1' : undefined}
								role={loading ? 'button' : undefined}
								aria-disabled={loading ? 'true' : undefined}
								disabled={loading}
							>
								<span
									className={`${loading ? 'loading loading-spinner' : ''}`}
								></span>
								Submit
							</button>
						</div>
					</form>
				</dialog>
			)}
		</div>
	)
}

export default UserNewSearch
