import React from 'react'
import dayjs from 'dayjs'
import {
	ComputerDesktopIcon,
	GlobeAltIcon,
	XMarkIcon,
	ClockIcon,
} from '@heroicons/react/24/outline'

const DeviceCard = ({
	device,
	isCurrent,
	isDarkMode,
	onRemove,
	isRemoving,
}) => {
	const lastCheckIn = dayjs(device.lastCheckIn).fromNow()

	return (
		<div
			className={`${isDarkMode ? 'bg-gray-700' : 'bg-white'} border ${
				isDarkMode ? 'border-gray-600' : 'border-gray-200'
			} rounded-lg p-6`}
		>
			<div className="flex items-start justify-between">
				<div className="space-y-3">
					<div className="flex items-center gap-3">
						<GlobeAltIcon className="h-6 w-6" />
						<span
							className={`font-medium text-lg text-${
								isDarkMode ? 'white' : 'gray-900'
							}`}
						>
							{device.browser}
						</span>
						<span
							className={`px-3 py-1 ${
								isCurrent ? 'text-md font-medium' : 'text-xs'
							} rounded-full ${
								isCurrent
									? 'bg-green-500/15 text-green-500'
									: `${
											isDarkMode
												? 'bg-gray-600 text-gray-300'
												: 'bg-gray-200 text-gray-700'
									  }`
							} flex items-center gap-1`}
						>
							{isCurrent ? (
								'Current'
							) : (
								<>
									<ClockIcon className="h-4 w-4" />
									{lastCheckIn}
								</>
							)}
						</span>
					</div>
					<div
						className={`flex items-center gap-3 text-base text-${
							isDarkMode ? 'gray-300' : 'gray-600'
						}`}
					>
						<ComputerDesktopIcon className="h-5 w-5" />
						{device.os}
					</div>
					<div
						className={`text-sm text-${isDarkMode ? 'gray-400' : 'gray-500'}`}
					>
						{device.city}, {device.country}
					</div>
				</div>
				{!isCurrent && (
					<button
						onClick={onRemove}
						disabled={isRemoving}
						className={`p-2 rounded-md text-sm ${
							isDarkMode
								? 'bg-red-500/10 text-red-500 hover:bg-red-500/20'
								: 'bg-red-50 text-red-600 hover:bg-red-100'
						} transition-colors`}
					>
						{isRemoving ? (
							<div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
						) : (
							<span className="flex items-center gap-1">
								<XMarkIcon className="h-3 w-3" />
								Remove
							</span>
						)}
						<span className="sr-only">Remove device</span>
					</button>
				)}
			</div>
		</div>
	)
}

export default DeviceCard
