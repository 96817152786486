import clsx from 'clsx'

const formClasses =
	'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-2 py-1 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'
// Reduced vertical padding slightly

function Label({ id, children }) {
	return (
		<label htmlFor={id} className="block text-xs font-medium text-gray-700">
			{children}
		</label>
	)
}

export function TextField({
	id,
	label,
	type = 'text',
	className = '',
	onChange,
	value,
	...props
}) {
	return (
		<div className={className}>
			{label && <Label id={id}>{label}</Label>}
			<input
				onChange={onChange}
				id={id}
				type={type}
				value={value}
				{...props}
				className={formClasses}
			/>
		</div>
	)
}

export function SelectField({ id, label, className = '', ...props }) {
	return (
		<div className={className}>
			{label && <Label id={id}>{label}</Label>}
			<select id={id} {...props} className={clsx(formClasses)} />
		</div>
	)
}
