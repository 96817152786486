import React from 'react'
import MultiSelect from 'react-select'

export default function MultiSelectComponent({ options, value, onChange }) {
	return (
		<MultiSelect
			options={options}
			closeMenuOnSelect={false}
			isMulti
			value={value}
			onChange={onChange}
		/>
	)
}
