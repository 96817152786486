import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { TextField } from './Fields'
import { SlimLayout } from './SlimLayout'
import { resetPassword } from '../../services/authService'
import { Logo } from '../LandingPage/Logo'

function PasswordReset() {
	const { token } = useParams()
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')

	const handleSubmit = async e => {
		e.preventDefault()
		setError('')
		setMessage('')

		if (password !== confirmPassword) {
			setError('Passwords do not match.')
			return
		}

		try {
			await resetPassword(token, password)
			setMessage('Your password has been successfully reset.')
		} catch (err) {
			if (err.response && err.response.data) {
				const apiErrorMessage =
					err.response.data.message ||
					'Failed to reset password. Please try again.'
				setError(apiErrorMessage)
			} else {
				setError('An unexpected error occurred. Please try again later.')
			}
		}
	}

	return (
		<SlimLayout>
			<div className="flex">
				<>
					<Link to="/" aria-label="Home">
						<Logo
							color={'text-gray-600 text-lg'}
							height={'45px'}
							width={'45px'}
						/>
					</Link>
				</>
			</div>
			<h2 className="mt-3 mb-4 text-lg font-semibold text-gray-900">
				Reset Your Password
			</h2>
			<form onSubmit={handleSubmit} className="mt-1 grid grid-cols-1 gap-y-8">
				{message && <div className="text-green-500">{message}</div>}
				{error && <div className="text-red-500">{error}</div>}

				<TextField
					className="col-span-full -my-2"
					label="New Password"
					id="password"
					name="password"
					type="password"
					required
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>

				<TextField
					className="col-span-full -my-2"
					label="Confirm New Password"
					id="confirmPassword"
					name="confirmPassword"
					type="password"
					required
					value={confirmPassword}
					onChange={e => setConfirmPassword(e.target.value)}
				/>

				<div>
					<button
						type="submit"
						className="submit-button group inline-flex items-center justify-center py-2 px-4 text-sm font-semibold bg-blue-600 text-white hover:text-gray-900 hover:bg-gray-50 active:bg-gray-100 focus:outline-none focus-visible:outline-blue-600 rounded-full w-full"
					>
						Reset Password
					</button>
				</div>
				<p className="mt-0 text-sm text-gray-700">
					Remembered your password?{' '}
					<Link
						to="/account/login"
						className="font-medium text-blue-600 hover:underline"
					>
						Sign in
					</Link>
				</p>
			</form>
		</SlimLayout>
	)
}

export default PasswordReset
