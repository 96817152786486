import { useState, useEffect } from 'react'
import Axios from 'axios'

import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'

import Sidebar from '../../../Sidebar'
import { Logo } from '../../../LandingPage/Logo'

import { useDarkMode } from '../../../../context/DarkModeContext'

import Scraper from './Scraper'

export default function SearchSettings() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [sidebarOpen, setSidebarOpen] = useState(false)

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	useEffect(() => {
		return () => {}
	}, [])

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main
					className={
						isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'
					}
				>
					<Scraper isDarkMode={isDarkMode} />
				</main>
			</div>
		</>
	)
}
