import React, { useEffect, useRef } from 'react'
import L from 'leaflet'

// import markerIcon from 'leaflet/dist/images/marker-icon.png'
// import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import 'leaflet-control-geocoder'
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'leaflet/dist/leaflet.css'

import './loadcollapse.css'

function ViewOnMap({ isModalOpen, closeModal, stops }) {
	const mapContainerRef = useRef(null)
	const map = useRef(null)

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	useEffect(() => {
		if (isModalOpen && stops?.length) {
			const bounds = new L.LatLngBounds()

			const summaryControl = L.control({ position: 'topright' }) // <-- Add this line

			summaryControl.onAdd = function () {
				this._div = L.DomUtil.create('div', 'summary-control')
				this.update()
				return this._div
			}

			summaryControl.update = function (props) {
				this._div.innerHTML = props
					? `<span class="text-gray-800 font-bold">Distance: ${(
							parseFloat(props.distance) * 0.621371
					  ).toFixed(2)} miles</span>
                 <br> <span class="text-gray-800 font-bold">Time: ${
										props.time
									} </span>`
					: 'No Route Selected'
			}

			if (!map.current) {
				map.current = L.map(mapContainerRef.current).setView(
					[stops[0].coordinates.lat, stops[0].coordinates.lng],
					10
				)

				L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(
					map.current
				)

				summaryControl.addTo(map.current)
			}

			// Draw stops
			stops.forEach((stop, index) => {
				const customIcon = L.divIcon({
					className: 'custom-map-icon',
					html: `<div style="background-color: #c30000; width: 25px; height: 25px; border-radius: 50%; text-align: center; line-height: 25px; color: white">${
						index + 1
					}</div>`,
					iconSize: [25, 25],
					iconAnchor: [12, 12],
				})

				L.marker([stop.coordinates.lat, stop.coordinates.lng], {
					icon: customIcon,
				})?.addTo(map.current)

				bounds.extend([stop.coordinates.lat, stop.coordinates.lng])
			})

			map.current.fitBounds(bounds)

			const routingControl = L.Routing.control({
				waypoints: stops.map(stop =>
					L.latLng(stop.coordinates.lat, stop.coordinates.lng)
				),
				routeWhileDragging: false,
				show: false,
				createMarker: () => null,
				router: new L.Routing.OSRMv1({
					serviceUrl: 'https://router.project-osrm.org/route/v1',
				}),
			}).addTo(map.current)

			routingControl.on('routesfound', function (e) {
				const routes = e.routes
				const summary = routes[0].summary

				const formatter = new L.Routing.Formatter()

				// Update the custom control with the summary information
				summaryControl.update({
					distance: formatter.formatDistance(summary.totalDistance),
					time: formatter.formatTime(summary.totalTime),
				})
			})

			return () => {
				if (map.current) {
					map.current.off()
					map.current.remove()
					map.current = null
				}
			}
		}
	}, [isModalOpen, stops])

	return (
		<div className="">
			<dialog
				onClick={handleOutsideClick}
				className={`modal ${isModalOpen ? 'modal-open' : ''}`}
			>
				<form
					method="dialog"
					className={`view-on-map-modal-box-custom modal-box`}
				>
					<h2 className="font-bold text-lg text-gray-200">Route on Map</h2>

					{/* Map container */}
					<div ref={mapContainerRef} className="map-container"></div>

					<div className="modal-action">
						<button onClick={closeModal} className="btn">
							Close
						</button>
					</div>
				</form>
			</dialog>
		</div>
	)
}

export default ViewOnMap
