import {
	ArrowLongLeftIcon,
	ArrowLongRightIcon,
} from '@heroicons/react/24/outline'

import audioFile from '../../media/impressed.mp3'

export function colorBasedOnAge(lastUpdatedAt) {
	const baseStyle = {
		textAlign: 'center',
		borderRadius: '3px',
		width: '40px',
		height: '25px',
		fontSize: '14px', // Adjust the font size as needed
	}

	if (lastUpdatedAt === undefined) {
		return {
			...baseStyle,
			color: '#28a358',
			backgroundColor: '#e4f6e7',
			width: '75px',
		}
	}

	const timeSinceUpdate = Date.now() - new Date(lastUpdatedAt)
	const hoursSinceUpdate = timeSinceUpdate / 1000 / 60 / 60

	if (hoursSinceUpdate < 4) {
		return {
			...baseStyle,
			color: '#28a358',
			backgroundColor: '#e4f6e7',
		}
	} else if (hoursSinceUpdate < 24) {
		return {
			...baseStyle,
			color: '#dc8132',
			backgroundColor: '#fee9c8',
		}
	} else {
		return {
			...baseStyle,
			color: '#5a6278',
			backgroundColor: '#e2e5ea',
		}
	}
}

export function colorBasedOnAgeSimilarLoads(lastUpdatedAt) {
	const baseStyle = {
		textAlign: 'center',
		borderRadius: '3px',
		width: '35px',
		height: '20px',
		fontSize: '14px', // Adjust the font size as needed
	}

	if (lastUpdatedAt === undefined) {
		return {
			...baseStyle,
			color: '#28a358',
			backgroundColor: '#e4f6e7',
			width: '75px',
		}
	}

	const timeSinceUpdate = Date.now() - new Date(lastUpdatedAt)
	const hoursSinceUpdate = timeSinceUpdate / 1000 / 60 / 60

	if (hoursSinceUpdate < 4) {
		return {
			...baseStyle,
			color: '#28a358',
			backgroundColor: '#e4f6e7',
		}
	} else if (hoursSinceUpdate < 24) {
		return {
			...baseStyle,
			color: '#dc8132',
			backgroundColor: '#fee9c8',
		}
	} else {
		return {
			...baseStyle,
			color: '#5a6278',
			backgroundColor: '#e2e5ea',
		}
	}
}

export function formatLastSeenDate(date) {
	if (date === undefined) return 'Loadboard'

	const dateArg = new Date(date)

	const now = new Date()
	const diff = now.getTime() - dateArg.getTime()
	const seconds = Math.floor(diff / 1000)
	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const days = Math.floor(hours / 24)
	const months = Math.floor(days / 30)

	if (seconds < 60) {
		return '1m'
	} else if (minutes < 60) {
		return minutes + 'm'
	} else if (hours < 24) {
		return hours + 'hr'
	} else if (days < 30) {
		return days + 'd'
	} else {
		return months + 'month'
	}
}

export const ArrowDirection = ({ load }) => {
	const firstCity = `${load.pickup.address.city}, ${load.pickup.address.state}`
	const lastCity = `${load.dropOff.address.city}, ${load.dropOff.address.state}`

	const isRoundTrip = firstCity === lastCity

	return isRoundTrip ? (
		<span className="flex justify-center items-center">
			<ArrowLongLeftIcon className="h-5 w-5 -mr-2" />
			<ArrowLongRightIcon className="h-5 w-5" />
		</span>
	) : (
		<span className="flex justify-center items-center">
			<ArrowLongRightIcon className="h-5 w-5" />
		</span>
	)
}

export function validateTimeZone(timeZone, dayjs) {
	try {
		if (dayjs.tz.zone(timeZone)) {
			return timeZone
		}
		throw new Error('Invalid time zone')
	} catch (e) {
		console.error('Invalid time zone specified:', timeZone)
		return 'America/New_York' // default timezone if validation fails
	}
}

export function dateDifference(date1, date2) {
	const startDate = new Date(date1)
	const endDate = new Date(date2)

	const diffInMilliseconds = endDate - startDate

	const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))

	if (hours < 24) {
		return `${hours}hrs`
	}

	const days = Math.floor(hours / 24)
	const remainingHours = hours % 24

	return `${days}d ${remainingHours}hrs`
}

export function truncateString(cityName, maxLength = 15) {
	// const maxLength = 15

	return cityName.length > maxLength
		? cityName.substr(0, maxLength) + '...'
		: cityName
}

const audio = new Audio(audioFile)

export function checkNewLoad(data, loadCache, isAudioOn) {
	let isNewLoadDetected = false

	if (data?.loads?.length && isAudioOn) {
		data.loads.forEach(load => {
			const fourMinutesAgo = new Date(Date.now() - 4 * 60 * 1000)

			const loadCreatedAt = new Date(load.createdAt)
			const isLoadExist = loadCache.get(load._id)
			const isTime = loadCreatedAt > fourMinutesAgo

			if (loadCreatedAt > fourMinutesAgo && !isLoadExist) {
				loadCache.set(load._id, true)
				isNewLoadDetected = true
			}
		})

		if (isNewLoadDetected && isAudioOn) {
			audio.play()
			console.log('New load(s) detected. Playing sound.')
		}
	}
}

export const queryFormatter = data => {
	const pickup = {
		lat: data.pickup.location.coordinates[1],
		lng: data.pickup.location.coordinates[0],
		maxDist: '50',
	}

	const dropOff = {
		lat: data.dropOff.location.coordinates[1],
		lng: data.dropOff.location.coordinates[0],
		maxDist: '150',
	}

	const equipments = [data?.equipment]

	const sourceFormat =
		data.broker.company === 'AMAZON' ? ['AMAZON'] : ['DAT', 'AMAZON']

	const price = 0
	const pricePerMile = 0
	const stopCount = data.stopCount
	const weight = 0
	const source = sourceFormat //['AMAZON']
	const paginate = { size: '100', number: '1' }
	const distance = data?.distance
	const excludedBrokers = null
	const excludeOrIncludeBrokers = true
	const sortBy = { updatedAt: -1 }

	const query = {
		dropOff,
		pickup,
		equipments,
		source,
		price,
		pricePerMile,
		sortBy,
		stopCount,
		weight,
		paginate,
		distance,
		excludedBrokers,
		excludeOrIncludeBrokers,
	}

	return query
}

export const sourcesList = [
	{ value: 'AMAZON', label: 'Amazon' },
	{ value: 'DAT', label: 'DAT' },
	// { value: 'JB HUNT', label: 'JB Hunt' },
	// { value: 'TQL', label: 'TQL' },
]
