import { useState, useEffect } from 'react'

import dayjs from 'dayjs'
import moment from 'moment'
import Axios from 'axios'

import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'
import { ClockIcon } from '@heroicons/react/24/solid'

import { useDarkMode } from '../../context/DarkModeContext'

import { getAnalitics } from '../../services/httpService'

import { Logo } from '../LandingPage/Logo'
import AnalyticsCard01 from './Partials/AnalyticsCard01'
import AnalyticsCard02 from './Partials/AnalyticsCard02'

import Sidebar from '../Sidebar'

const labelsGenerator = async () => {
	const result = []
	const today = new Date()

	for (let i = 0; i < 7; i++) {
		const date = moment(today).subtract(i, 'days').format('L')

		result.push(date)
	}

	return result
}

export default function AnalyticsMain() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [sidebarOpen, setSidebarOpen] = useState(false)

	const [analytics, setAnalytics] = useState(null)
	const [labels, setLabels] = useState([])
	const [loading, setLoading] = useState(true)

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	useEffect(() => {
		const initial = async () => {
			setLoading(true)

			const result = await Axios.get(`/api/analitics`)
			const data = result.data

			// const data = await getAnalitics()
			const lbls = await labelsGenerator()

			setLabels(lbls)
			setAnalytics(data)
			setLoading(false)
		}

		initial()
	}, [])

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main className={isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}>
					<div className={`${isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}`}>
						{loading ? (
							<div className="flex flex-col justify-center h-screen">
								<h1
									className={`text-center ${
										isDarkMode ? 'text-white' : 'text-black'
									} text-4xl mb-96`}
								>
									Loading...
								</h1>
							</div>
						) : (
							<div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
								<span className="text-gray-500 sm:text-xs py-4">
									<ClockIcon
										className="h-4 w-4 text-gray-500 inline-block mb-1 mr-1"
										aria-hidden="true"
									/>
									updated {moment(analytics?.updatedAt).fromNow()}
								</span>

								<div className="grid grid-cols-12 gap-6">
									<AnalyticsCard01
										analytics={analytics?.last7Days}
										labels={labels}
										prevAnalytics={analytics?.prevLast7Days}
									/>

									<AnalyticsCard02
										analytics={analytics?.last24Hours}
										prevAnalytics={analytics?.prevLast24Hours}
									/>
								</div>
							</div>
						)}
					</div>
				</main>
			</div>
		</>
	)
}
