import React from 'react'

export default function SavedSearchControls({ openModal, openNewSearchModal }) {
	return (
		<div className="-mb-2  flex items-center text-sm">
			<button
				className="text-md text-blue-600 hover:underline"
				onClick={openModal}
			>
				Saved Searches
			</button>
			<div className="border-l-2 mx-2 h-6 align-middle border-gray-700"></div>{' '}
			<button
				className="text-md text-blue-600 hover:underline"
				onClick={openNewSearchModal}
			>
				Save This Search
			</button>
		</div>
	)
}
