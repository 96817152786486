import React from 'react'
import axios from 'axios'

import { getCurrentUser } from '../../services/authService'
import { baseURL } from '../../config'

function UpgradeSection({ isDarkMode }) {
	const upgradeUserClick = () => {
		window.location.href = '/payment'
	}

	const user = getCurrentUser()

	const handleManageBilling = async () => {
		try {
			if (!user || !user.email) {
				console.error('User email is not available.')
				return
			}

			const response = await axios.get(
				`${baseURL}/api/stripe/customer-portal`,
				{
					params: { email: user.email },
				}
			)

			window.location.href = response.data
		} catch (error) {
			console.error('Error redirecting to Stripe Customer Portal', error)
		}
	}

	const backgroundColor = isDarkMode ? '#1c2126' : '#f5f5f7'
	const textColor = isDarkMode ? 'text-gray-100' : 'text-gray-800'
	const buttonGradient = isDarkMode
		? 'bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700'
		: 'bg-gradient-to-r from-blue-500 to-green-500 hover:from-blue-600 hover:to-green-600'
	const linkColor = isDarkMode
		? 'text-blue-400 hover:text-blue-300'
		: 'text-blue-600 hover:text-blue-500'

	return (
		<div
			className={`max-w-3xl mx-auto p-6 rounded-lg shadow-md border border-gray-300 my-4`}
			style={{ backgroundColor }}
		>
			<h2
				className={`text-3xl font-extrabold text-center mb-4 ${textColor} tracking-wide`}
			>
				Upgrade Your Plan!
			</h2>
			<p className={`text-md md:text-lg text-center ${textColor} mb-6 px-4`}>
				Please subscribe or renew your plan to continue enjoying our services.
			</p>

			<div className="flex flex-col items-center gap-4">
				<button
					onClick={upgradeUserClick}
					className={`${buttonGradient} text-white text-lg font-bold py-3 px-8 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1`}
				>
					Upgrade Now
				</button>

				<a
					onClick={handleManageBilling}
					className={`cursor-pointer ${linkColor} text-lg py-2 transition duration-300 ease-in-out`}
					role="button"
				>
					Manage Billing
				</a>
			</div>
		</div>
	)
}

export default UpgradeSection
