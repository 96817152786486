/*global chrome*/

import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { baseURL } from '../config'

export async function simpleLogin(user, source) {
	axios.post(`${baseURL}/api/users/login`, user).then(res => {
		const { token } = res.data

		console.log('simpleLogin source', source)

		if (source === 'extension') {
			window.location.href = `https://relay.amazon.com?token=${token}`

			window.opener.postMessage(
				{
					source: 'loadfetcherLogin',
					token: token,
				},
				'*'
			)

			localStorage.clear()

			document.cookie.split(';').forEach(c => {
				document.cookie = c
					.replace(/^ +/, '')
					.replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
			})
			window.close()
		} else {
			token && localStorage.setItem('jwtToken', token)
			user.email && localStorage.setItem('email', user.email)
			window.location = '/loadboard'
		}
	})
}

export async function register(user) {
	const res = await axios.post(baseURL + '/api/users/register', user)

	console.log('res', res)
	return res
}

const sendTokenToChromeExtension = (extensionId, token) => {
	if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
		chrome.runtime.sendMessage(extensionId, token)
	} else {
		console.log('Chrome extension messaging API not available.')
	}
}

export async function login({
	user,
	location,
	navigate,
	sourceExtension,
	windowOpender,
	toPayments = false,
	redirectUri,
	userToken,
}) {
	return await axios
		.post(`${baseURL}/api/users/login`, {
			...user,
			source: sourceExtension,
			userToken,
			token: userToken,
		})
		.then(async res => {
			const token = res.data?.token
			const extensionToken = res.data?.extensionToken

			if (token) localStorage.setItem('jwtToken', token)

			const productionExtensionId = 'ihcgicdogclbieclnldfjmlglkolankb'
			const developmentExtensionId = 'omgobnjbempcolkjnfphklponcmlooko'
			const extensionId = productionExtensionId

			if (extensionToken) {
				sendTokenToChromeExtension(extensionId, {
					extensionToken,
				})
			}

			const params = new URLSearchParams(location.search)

			if (sourceExtension && windowOpender) {
				if (!extensionToken) return

				windowOpender?.postMessage(
					{
						source: 'loadfetcherLogin',
						token: extensionToken,
					},
					'*'
				)

				window.close()
			} else if (params.get('source') === 'extension') {
				window.location.href = `https://relay.amazon.com?token=${token}`
				window.opener.postMessage(
					{
						source: 'loadfetcherLogin',
						token: extensionToken,
					},
					'*'
				)
				window.close()
			} else {
				token && localStorage.setItem('jwtToken', token)
				user.email && localStorage.setItem('email', user.email)
			}
		})
		.then(async () => {
			if (toPayments) return true

			if (redirectUri) {
				window.location = redirectUri
			} else {
				window.location = '/loadboard'
			}

			// navigate('/loadboard')

			return setTimeout(() => {
				navigate('/loadboard')
			}, 2000)
		})
		.catch(err => {
			if (err.response && err.response.status === 403) {
				toast.error('You signed up with Google. Log in with Google!', {
					position: 'top-center',
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
			}

			throw err
		})
}

export function getCurrentUser() {
	try {
		const jwt = localStorage.getItem('jwtToken')

		if (!jwt) return null

		const user = jwtDecode(jwt)

		return user
	} catch (error) {
		return error
	}
}

export const requestPasswordReset = async email => {
	const response = await axios.post(`${baseURL}/api/user/reset-password`, {
		email,
	})
	return response.data
}

export const resetPassword = async (token, newPassword) => {
	const response = await axios.post(
		`${baseURL}/api/user/reset-password/${token}`,
		{ newPassword }
	)
	return response.data
}

export function logout() {
	try {
		localStorage.removeItem('jwtToken')
		localStorage.removeItem('email')
	} catch (err) {}
}

export async function checkCurrentUser() {
	try {
		const { exp } = getCurrentUser()

		if (Date.now() >= exp * 1000) {
			logout()
			window.location = '/'
			return window.location.reload()
		}
	} catch (error) {}
}

// const upgradeUser = async (user, plan) => {}

export default {
	login,
	getCurrentUser,
	logout,
	checkCurrentUser,
}
