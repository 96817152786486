import React, { useState } from 'react'

import { addLoadboardCreds } from '../../../../services/httpService'
import { getCurrentUser } from '../../../../services/authService'

function AddNewScraper() {
	const [state, setState] = useState({
		loadboard: 'not-selected',
		email: null,
		password: null,
	})

	const handleChange = e => {
		const { name, value } = e.target
		setState(prev => ({
			...prev,
			[name]: value,
		}))
	}

	const createCard = async () => {
		if (!state.loadboard || !state.email || !state.password) {
			return
		}

		const user = getCurrentUser()

		try {
			await addLoadboardCreds({ ...state, userId: user.id })
			document.getElementById('add_new_scraper_creds').close()
		} catch (err) {
			console.log(err)
			return
		}
	}

	return (
		<dialog id="add_new_scraper_creds" className="modal">
			<div className="modal-box w-11/12 max-w-5xl">
				<h3 className="text-white font-bold text-lg mb-5">Add Loadboard</h3>

				<label className="label">
					<span className="label-text">Select Loadboard</span>
				</label>
				<select
					value={state.loadboard || 'not-selected'}
					name="loadboard"
					onChange={handleChange}
					className="text-white select select-bordered w-full max-w-xxl"
				>
					<option value="not-selected" disabled>
						Choose Loadboard
					</option>
					<option value="JB HUNT">JB Hunt</option>
					<option value="COYOTE">Coyote</option>
					<option value="AMAZON">Relay Amazon</option>
					<option value="DAT">DAT</option>
					<option value="DAT2">DAT2</option>
					<option value="DAT3">DAT3</option>
					<option value="TQL">TQL</option>
					<option value="XPO">XPO</option>
					<option value="EMERGE">Emerge</option>
					<option value="SWIFT">Swift</option>
				</select>

				<div className="my-5 form-control w-full">
					<label className="label">
						<span className="label-text">Email</span>
					</label>
					<input
						onChange={handleChange}
						type="email"
						name="email" // Added name attribute
						placeholder="Type your email"
						className="text-white input input-bordered w-full"
					/>
				</div>

				<div className="my-5 form-control w-full">
					<label className="label">
						<span className="label-text">Password</span>
					</label>
					<input
						onChange={handleChange}
						type="password"
						name="password" // Added name attribute
						placeholder="Type your password"
						className="text-white input input-bordered w-full"
					/>
				</div>

				<div className="modal-action">
					<form method="dialog">
						<button className="btn btn-neutral mx-2">Close</button>
						<button
							type="button"
							onClick={createCard}
							className="btn btn-success"
						>
							Submit
						</button>
					</form>
				</div>
			</div>
		</dialog>
	)
}

export default AddNewScraper
