import React, { useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

import { Logo } from '../LandingPage/Logo'

import chromeLogo from '../../media/chrome-logo.png'

const UninstallFeedback = () => {
	const [reason, setReason] = useState('')
	const [additionalComments, setAdditionalComments] = useState('')
	const [submitted, setSubmitted] = useState(false)

	const handleSubmit = async event => {
		event.preventDefault()

		const feedbackData = {
			reason,
			additionalComments,
		}

		try {
			const jwt = localStorage.getItem('jwtToken')

			const response = await fetch('/api/user/uninstall-feedback', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: jwt,
				},
				body: JSON.stringify(feedbackData),
			})

			if (response.ok) {
				setSubmitted(true)
			} else {
				setSubmitted(true)
				throw new Error('Feedback submission failed')
			}
		} catch (error) {
			setSubmitted(true)
			console.error('Error:', error.message)
		}
	}

	return (
		<div className="bg-gray-100 min-h-screen">
			<div className="bg-white py-4 shadow">
				<div className="container mx-auto px-4">
					<Logo isLoadboard={false} />
				</div>
			</div>
			<div className="container mx-auto px-4 py-8">
				<div className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-lg">
					{submitted ? (
						<div className="text-center">
							<CheckCircleIcon className="mx-auto h-12 w-12 text-green-500" />
							<h1 className="mt-2 text-lg font-semibold text-gray-800">
								Thank you for your feedback!
							</h1>
						</div>
					) : (
						<>
							<h1 className="text-xl font-semibold text-gray-800 mb-4">
								We're sad to see you go!
							</h1>
							<p className="text-gray-700 mb-4">
								Please let us know why you decided to uninstall our extension:
							</p>
							<form onSubmit={handleSubmit} className="space-y-4">
								<div className="flex items-center">
									<input
										type="radio"
										id="difficulty"
										name="reason"
										value="Difficulty using it"
										className="mr-2"
										onChange={e => setReason(e.target.value)}
									/>
									<label htmlFor="difficulty" className="flex-1 text-gray-700">
										I had difficulty using it
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										id="features"
										name="reason"
										value="Lack of features"
										className="mr-2"
										onChange={e => setReason(e.target.value)}
									/>
									<label htmlFor="features" className="flex-1 text-gray-700">
										It lacked the features I needed
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										id="performance"
										name="reason"
										value="Performance issues"
										className="mr-2"
										onChange={e => setReason(e.target.value)}
									/>
									<label htmlFor="performance" className="flex-1 text-gray-700">
										Performance issues (e.g., slow, buggy)
									</label>
								</div>
								<div className="flex items-center">
									<input
										type="radio"
										id="other"
										name="reason"
										value="Other"
										className="mr-2"
										onChange={e => setReason(e.target.value)}
									/>
									<label htmlFor="other" className="flex-1 text-gray-700">
										Other
									</label>
								</div>

								<div>
									<label
										htmlFor="additionalComments"
										className="block mb-2 text-gray-700"
									>
										Additional Comments:
									</label>
									<textarea
										id="additionalComments"
										name="additionalComments"
										rows="4"
										className="w-full p-2 border border-gray-300 rounded"
										onChange={e => setAdditionalComments(e.target.value)}
									></textarea>
								</div>

								<button
									type="submit"
									style={{
										backgroundColor: '#1D4ED8',
										color: 'white',
										padding: '8px 16px',
										width: '100%',
										borderRadius: '0.5rem',
									}}
									onMouseOver={e =>
										(e.currentTarget.style.backgroundColor = '#2563EB')
									}
									onMouseOut={e =>
										(e.currentTarget.style.backgroundColor = '#1D4ED8')
									}
									className="focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 hover:bg-blue-700"
								>
									Submit Feedback
								</button>
							</form>
						</>
					)}

					<div className="mt-6 text-center text-gray-700 flex items-center justify-center">
						<span>If you change your mind, you can always </span>
						<a
							href="https://chromewebstore.google.com/detail/relay-auto-refresher-post/ihcgicdogclbieclnldfjmlglkolankb"
							target="_blank"
							rel="noreferrer"
							className="inline-flex items-center justify-center px-5 py-1 border border-blue-600 text-base font-medium rounded-md text-blue-600 bg-transparent hover:bg-blue-100 ml-2"
						>
							<img
								className="w-6 h-6 mr-2"
								src={chromeLogo}
								alt="Chrome Logo"
							/>
							Reinstall
						</a>
						<span>.</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UninstallFeedback
