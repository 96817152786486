import { Container } from './Container'
import backgroundImage from './images/background-faqs.jpg'

const faqs = [
	[
		{
			question: 'What do you need to sign up?',
			answer: 'An active email address.',
		},
		{
			question: 'How do can I cancel my subscription?',
			answer:
				'Just head into settings page on Chrome Extension or website and click "Manage Subscription". You can cancel your subscription there.',
		},
		{
			question: 'How does your pricing work? Are there any hidden fees?',
			answer: 'Our pricing model is transparent with no hidden fees.',
		},
	],
	[
		{
			question: 'What types of payment do you accept?',
			answer:
				'We accept payments through Stripe, which supports a wide range of global card networks.',
		},
		{
			question: 'How can I contact customer support?',
			answer:
				'You can contact us via email <a href="mailto:info@loadfetcher.com" class="text-blue-600 font-semibold hover:underline">info@loadfetcher.com</a> or via Telegram chat <a href="https://t.me/loadfetcher_support" class="text-blue-600 font-semibold hover:underline">@loadfetcher_support</a>. We usually respond within 1-24 hours.',
		},
		{
			question: 'What is your cancellation policy?',
			answer:
				"You're free to cancel your subscription at any time; the service will remain active until the end of your current billing cycle.",
		},
	],
	[
		{
			question: 'How often do you release new features or updates?',
			answer:
				'We consistently roll out new features and updates to enhance your experience.',
		},
		{
			question:
				'Is there a free trial available, and do I need a credit card to sign up?',
			answer:
				"While we don't offer a free trial, we do have a free tier available that you can use without providing payment information. This allows you to explore our basic features at no cost. For access to premium features, please visit our pricing page to choose a paid plan that best suits your needs. Payment information is required only when signing up for a paid subscription.",
		},
	],
]

export function Faqs() {
	return (
		<section
			id="faq"
			aria-labelledby="faq-title"
			className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
		>
			<img
				className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
				src={backgroundImage}
				alt=""
				width={1558}
				height={946}
				unoptimized="true"
			/>
			<Container className="relative">
				<div className="mx-auto max-w-2xl lg:mx-0">
					<h2
						id="faq-title"
						className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
					>
						Frequently asked questions
					</h2>
				</div>
				<ul
					role="list"
					className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
				>
					{faqs.map((column, columnIndex) => (
						<li key={columnIndex}>
							<ul role="list" className="flex flex-col gap-y-8">
								{column.map((faq, faqIndex) => (
									<li key={faqIndex}>
										<h3 className="font-display text-lg leading-7 text-slate-900">
											{faq.question}
										</h3>
										<p
											className="mt-4 text-sm text-slate-700"
											dangerouslySetInnerHTML={{ __html: faq.answer }}
										></p>
									</li>
								))}
							</ul>
						</li>
					))}
				</ul>
			</Container>
		</section>
	)
}
