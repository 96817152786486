import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import {
	ComputerDesktopIcon,
	GlobeAltIcon,
	XMarkIcon,
	ClockIcon,
} from '@heroicons/react/24/outline'

const DeviceManagementModal = ({ isOpen, onClose, isDarkMode }) => {
	const [loading, setLoading] = useState(false)
	const [currentDevice, setCurrentDevice] = useState(null)
	const [otherDevices, setOtherDevices] = useState([])
	const [removingDevices, setRemovingDevices] = useState({})

	useEffect(() => {
		const fetchDevices = async () => {
			try {
				if (!isOpen) return
				setLoading(true)

				const token = localStorage.getItem('jwtToken')
				const user = jwtDecode(token)

				const response = await axios.get('/api/user/devices')
				const allDevices = response.data

				const current = allDevices.find(
					device => device.deviceId === user.deviceId
				)
				const others = allDevices
					.filter(device => device.deviceId !== user.deviceId)
					.sort((a, b) => new Date(a.lastCheckIn) - new Date(b.lastCheckIn))

				setCurrentDevice(current)
				setOtherDevices(others)
			} catch (error) {
				console.error('Error fetching devices:', error)
			} finally {
				setLoading(false)
			}
		}

		fetchDevices()
	}, [isOpen])

	if (!isOpen) return null

	const handleRemoveDevice = async deviceId => {
		try {
			setRemovingDevices(prev => ({ ...prev, [deviceId]: true }))
			await axios.post('/api/user/terminate-session', {
				deviceId: deviceId,
			})

			const response = await axios.get('/api/user/devices')
			const allDevices = response.data

			const token = localStorage.getItem('jwtToken')
			const user = jwtDecode(token)

			const current = allDevices.find(
				device => device.deviceId === user.deviceId
			)
			const others = allDevices.filter(
				device => device.deviceId !== user.deviceId
			)

			setCurrentDevice(current)
			setOtherDevices(others)

			const deviceLimit = user?.deviceLimit || 3

			if (allDevices.length <= deviceLimit) {
				try {
					const refreshResponse = await axios.post(
						'/api/user/refresh-token/web'
					)
					const newToken = refreshResponse.data.token

					if (refreshResponse.data.success) {
						localStorage.setItem('jwtToken', newToken)
						console.log('Token refreshed successfully after device removal')
						onClose()
					}
				} catch (error) {
					console.error('Error refreshing token after device removal:', error)
				}
			}
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setRemovingDevices(prev => ({ ...prev, [deviceId]: false }))
		}
	}

	const token = localStorage.getItem('jwtToken')
	const user = jwtDecode(token)

	return (
		<div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
			<div
				className={`${
					isDarkMode ? 'bg-gray-800' : 'bg-white'
				} rounded-lg max-w-2xl w-full max-h-[95vh] overflow-hidden`}
			>
				<div className="p-6">
					<h2
						className={`text-${
							isDarkMode ? 'white' : 'gray-900'
						} text-xl font-bold mb-3`}
					>
						Device Management
					</h2>

					<div className="overflow-y-auto max-h-[70vh] pr-2">
						{loading ? (
							<div className="flex justify-center py-8">
								<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-500" />
							</div>
						) : (
							<div className="space-y-6">
								<div
									className={`p-3 rounded-md ${
										isDarkMode
											? 'bg-yellow-500/10 text-yellow-500'
											: 'bg-yellow-50 text-yellow-600'
									}`}
								>
									You have reached the maximum number of devices{' '}
									{user?.deviceLimit || 3} per account. Please remove other
									devices to continue.
								</div>

								{currentDevice && (
									<div className="space-y-3">
										<DeviceItem
											device={currentDevice}
											isCurrent={true}
											isDarkMode={isDarkMode}
										/>
									</div>
								)}

								<div
									className={`border-t ${
										isDarkMode ? 'border-gray-700' : 'border-gray-200'
									} my-1`}
								/>

								<div className="space-y-2">
									<h3
										className={`text-sm font-medium text-${
											isDarkMode ? 'white' : 'gray-900'
										}`}
									>
										Other Devices ({otherDevices.length})
									</h3>
									<div className="space-y-2">
										{otherDevices.map(device => (
											<DeviceItem
												key={device._id}
												device={device}
												isCurrent={false}
												isDarkMode={isDarkMode}
												onRemove={() => handleRemoveDevice(device._id)}
												isRemoving={removingDevices[device._id]}
											/>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

const DeviceItem = ({
	device,
	isCurrent,
	isDarkMode,
	onRemove,
	isRemoving,
}) => {
	const lastCheckIn = dayjs(device.lastCheckIn).fromNow()

	return (
		<div
			className={`${isDarkMode ? 'bg-gray-700' : 'bg-white'} border ${
				isDarkMode ? 'border-gray-600' : 'border-gray-200'
			} rounded-lg p-3`}
		>
			<div className="flex items-start justify-between">
				<div className="space-y-1">
					<div className="flex items-center gap-2">
						<GlobeAltIcon className="h-4 w-4" />
						<span
							className={`font-medium text-${
								isDarkMode ? 'white' : 'gray-900'
							}`}
						>
							{device.browser}
						</span>
						<span
							className={`px-2 py-0.5 text-xs rounded-full ${
								isCurrent
									? 'bg-green-500/15 text-green-500'
									: `${
											isDarkMode
												? 'bg-gray-600 text-gray-300'
												: 'bg-gray-200 text-gray-700'
									  }`
							} flex items-center gap-1`}
						>
							{isCurrent ? (
								'Current'
							) : (
								<>
									<ClockIcon className="h-3 w-3" />
									{lastCheckIn}
								</>
							)}
						</span>
					</div>
					<div
						className={`flex items-center gap-2 text-sm text-${
							isDarkMode ? 'gray-300' : 'gray-600'
						}`}
					>
						<ComputerDesktopIcon className="h-3.5 w-3.5" />
						{device.os}
					</div>
					<div
						className={`text-xs text-${isDarkMode ? 'gray-400' : 'gray-500'}`}
					>
						{device.city}, {device.country}
					</div>
				</div>
				{!isCurrent && (
					<button
						onClick={onRemove}
						disabled={isRemoving}
						className={`p-1.5 rounded-md text-xs ${
							isDarkMode
								? 'bg-red-500/10 text-red-500 hover:bg-red-500/20'
								: 'bg-red-50 text-red-600 hover:bg-red-100'
						} transition-colors`}
					>
						{isRemoving ? (
							<div className="h-3 w-3 animate-spin rounded-full border-2 border-current border-t-transparent" />
						) : (
							<span className="flex items-center gap-1">
								<XMarkIcon className="h-3 w-3" />
								Remove
							</span>
						)}
						<span className="sr-only">Remove device</span>
					</button>
				)}
			</div>
		</div>
	)
}

export default DeviceManagementModal
