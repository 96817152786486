import logo from '../../media/icons/logo-brand.svg'

export function Logo({ isLoadboard, color, width, height }) {
	const defaultWidth = isLoadboard ? '30px' : '45px'
	const defaultHeight = isLoadboard ? '30px' : '45px'
	const defaultColor = isLoadboard
		? 'text-gray-100 text-lg'
		: 'text-gray-600 text-xl'

	return (
		<div className="inline-flex items-center">
			<img
				src={logo}
				alt="Logo"
				style={{
					width: width || defaultWidth,
					height: height || defaultHeight,
				}}
			/>
			<p className={`${color || defaultColor} font-semibold btn-text`}>
				Load<span style={{ color: '#2463eb' }}>Fetcher</span>
			</p>
		</div>
	)
}
