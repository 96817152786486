import React from 'react'

import {
	PlayIcon,
	PauseIcon,
	SpeakerXMarkIcon,
	SpeakerWaveIcon,
	ArrowPathIcon,
} from '@heroicons/react/24/solid'

export default function NotificationControls({
	handleRefresh,
	isAutoRefresh,
	setIsAutoRefresh,
	countdown,
	isDarkMode,
	isAudioOn,
	setAudioOn,
}) {
	const textColor = isDarkMode ? 'text-gray-300' : 'text-gray-700'

	return (
		<div className="flex items-center text-sm">
			{isAudioOn ? (
				<SpeakerWaveIcon
					onClick={() => setAudioOn(!isAudioOn)}
					className={`cursor-pointer h-6 w-6 ${textColor} mx-1`}
				/>
			) : (
				<SpeakerXMarkIcon
					onClick={() => setAudioOn(!isAudioOn)}
					className={`cursor-pointer h-6 w-6 ${textColor} mx-1`}
				/>
			)}

			<span className={`text-lg ${textColor} mx-4`}>
				Refresh in {countdown}
			</span>
			<button onClick={() => setIsAutoRefresh(!isAutoRefresh)}>
				{isAutoRefresh ? (
					<PauseIcon className={`cursor-pointer h-6 w-6 ${textColor}`} />
				) : (
					<PlayIcon className={`cursor-pointer h-6 w-6 ${textColor}`} />
				)}
			</button>
			<div className={`border-l-2 mx-3 h-6 align-middle ${textColor}`}></div>
			{/* Divider */}
			<ArrowPathIcon
				onClick={handleRefresh}
				className={`cursor-pointer h-6 w-6 ${textColor}`}
			/>
		</div>
	)
}
