import React, { useState } from 'react'
import Axios from 'axios'

import Toast from '../Loadboard/Searches/Toast'
import styles from '../Loadboard/Searches/UserSearches.module.css'

function TelegramGroupConnect({ isModalOpen, closeModal, urlData }) {
	const [loading, setLoading] = useState(false)
	const [toastShow, setToastShow] = useState(false)
	const [toastMessage, setToastMessage] = useState('')
	const [isError, setIsError] = useState(false)

	const token = urlData?.token
	const chatId = urlData?.chatId
	const groupName = urlData?.groupName

	const handleConfirm = async () => {
		setLoading(true)
		try {
			const url = '/api/user/link-telegram-group'
			await Axios.post(url, { token, chatId })
			closeModal()
			setIsError(false)
			setToastShow(true)
			setToastMessage('Telegram group connected successfully!')

			setTimeout(() => {
				setToastShow(false)
			}, 5000)
		} catch (error) {
			setLoading(false)
			console.error('Error linking Telegram group:', error)
			setIsError(true)

			setToastMessage('Error linking Telegram group')

			setToastShow(true)

			setTimeout(() => {
				setToastShow(false)
			}, 5000)
		}
	}

	return (
		<div>
			{isModalOpen && (
				<dialog className={`modal ${isModalOpen ? 'modal-open' : ''}`}>
					<form
						method="dialog"
						className={`modal-box ${styles['searches-modal-box-custom']}`}
						style={{ backgroundColor: '#1c2126' }}
					>
						<h3 className="text-amber-200 mb-4 font-bold text-lg">
							Confirm Telegram Connection
						</h3>
						<p className="text-white mb-8  font-bold text-xl">
							Do you really want to connect to the{' '}
							<span className="text-green-400">{groupName}</span> Telegram
							group?
						</p>

						<div className="modal-action">
							<button
								onClick={closeModal}
								type="button"
								className="btn btn-ghost text-sm font-semibold text-white shadow-sm hover:bg-white/20"
							>
								Close
							</button>

							<button
								onClick={handleConfirm}
								type="button"
								className="btn btn-info px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
								disabled={loading}
							>
								{loading ? 'Connecting...' : 'Confirm'}
							</button>
						</div>
					</form>
				</dialog>
			)}

			{toastShow && <Toast message={toastMessage} error={isError} />}
		</div>
	)
}

export default TelegramGroupConnect
