import axios from 'axios'
import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import DeviceCard from './DeviceCard'

dayjs.extend(relativeTime)

function DevicesSettings({ activeTab, isDarkMode }) {
	const [loading, setLoading] = useState(false)
	const [currentDevice, setCurrentDevice] = useState(null)
	const [otherDevices, setOtherDevices] = useState([])
	const [removingDevices, setRemovingDevices] = useState({})

	const fetchDevices = async () => {
		try {
			setLoading(true)
			if (activeTab !== 'devices') return

			const token = localStorage.getItem('jwtToken')
			const user = jwtDecode(token)

			const response = await axios.get('/api/user/devices')
			const allDevices = response.data

			const current = allDevices.find(
				device => device?.deviceId === user?.deviceId
			)
			const others = allDevices
				.filter(device => device?.deviceId !== user?.deviceId)
				.sort((a, b) => new Date(a.lastCheckIn) - new Date(b.lastCheckIn))

			setCurrentDevice(current)
			setOtherDevices(others)
		} catch (error) {
			console.error('Error fetching devices:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchDevices()
	}, [activeTab])

	const handleRemoveDevice = async deviceId => {
		try {
			setRemovingDevices(prev => ({ ...prev, [deviceId]: true }))
			await axios.post('/api/user/terminate-session', {
				deviceId: deviceId,
			})
			await fetchDevices()
		} catch (error) {
			console.error('Error removing device:', error)
		} finally {
			setRemovingDevices(prev => ({ ...prev, [deviceId]: false }))
		}
	}

	const token = localStorage.getItem('jwtToken')
	const user = jwtDecode(token)

	if (loading) {
		return (
			<div className="flex justify-center py-8">
				<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-500" />
			</div>
		)
	}

	return (
		<div className="space-y-6 max-w-4xl mx-auto p-6">
			{currentDevice && (
				<div className="space-y-3">
					<DeviceCard
						device={currentDevice}
						isCurrent={true}
						isDarkMode={isDarkMode}
					/>
				</div>
			)}

			<div
				className={`border-t ${
					isDarkMode ? 'border-gray-700' : 'border-gray-200'
				} my-1`}
			/>

			<div className="space-y-2">
				<h3
					className={`text-sm font-medium text-${
						isDarkMode ? 'white' : 'gray-900'
					}`}
				>
					Other Devices ({otherDevices.length})
				</h3>
				<div className="space-y-2">
					{otherDevices.map(device => (
						<DeviceCard
							key={device._id}
							device={device}
							isCurrent={false}
							isDarkMode={isDarkMode}
							onRemove={() => handleRemoveDevice(device._id)}
							isRemoving={removingDevices[device._id]}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default DevicesSettings
