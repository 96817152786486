import React from 'react'

import AutocompleteCityOrigin from '../../Autocomplete/AutocompleteCityOrigin'

function OriginFilters(props) {
	const {
		filters,
		handleFilterChange,
		handleOriginChange,
		labels,
		styles,
		isDarkMode,
	} = props

	return (
		<div className="join col-span-5 flex">
			<div className="input-container flex-grow">
				<div>
					<AutocompleteCityOrigin
						origin={filters.origin}
						style={styles}
						className="input input-bordered join-item shrink mt-1 p-2 rounded-md w-full"
						name="origin"
						handleOriginChange={handleOriginChange}
						isDarkMode={isDarkMode}
					/>

					<label
						className={filters.pickupCity ? 'filled' : ''}
						style={{ ...labels, backgroundColor: 'transparent' }}
					>
						Origin
					</label>
				</div>
			</div>

			{/* Origin Radius */}
			<div className="radius-select select-wrap">
				<label
					style={{
						...labels,
						backgroundColor: isDarkMode ? '#22272c' : '#f5f5f7',
						color: isDarkMode ? '#C9D1D9' : '#3a4353',
					}}
				>
					Radius
				</label>
				<select
					name="pickupMaxDist"
					onChange={handleFilterChange}
					className="select select-bordered join-item mt-1 p-0 rounded-md w-full"
					style={styles}
					value={filters.pickupMaxDist}
					disabled={!filters.pickupCity}
				>
					<option value={10}>10 mi</option>
					<option value={25}>25 mi</option>
					<option value={50}>50 mi</option>
					<option value={75}>75 mi</option>
					<option value={100}>100 mi</option>
					<option value={150}>150 mi</option>
					<option value={200}>200 mi</option>
					<option value={250}>250 mi</option>
				</select>
			</div>
		</div>
	)
}

export default OriginFilters
