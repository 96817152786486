import { useState } from 'react'

import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'

import Sidebar from '../Sidebar'
import UsersList from './UsersList'
import ExtensionAnalytics from './ExtensionAnalytics'

import { Logo } from '../LandingPage/Logo'
import { useDarkMode } from '../../context/DarkModeContext'

export default function AdminUsers() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [activeTab, setActiveTab] = useState('users')

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<div className="tabs flex justify-center mt-4 mb-4">
					<button
						onClick={() => setActiveTab('users')}
						className={`tab tab-bordered text-2xl py-2 px-4 ${
							activeTab === 'users' ? 'tab-active' : ''
						}`}
					>
						Users
					</button>
					<button
						onClick={() => setActiveTab('extension')}
						className={`tab tab-bordered text-2xl py-2 px-4 ${
							activeTab === 'extension' ? 'tab-active' : ''
						}`}
					>
						Extension Analytics
					</button>
				</div>

				<main
					className={`${
						isDarkMode ? 'bg-dark-color' : 'bg-light-gray'
					} mx-auto max-w-screen-xl`}
				>
					{activeTab === 'users' && (
						<UsersList
							loading={loading}
							setLoading={setLoading}
							isDarkMode={isDarkMode}
						/>
					)}
					{activeTab === 'extension' && <ExtensionAnalytics />}
				</main>
			</div>
		</>
	)
}
