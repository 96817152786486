import { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

import {
	ArrowRightOnRectangleIcon,
	XMarkIcon,
	Cog6ToothIcon,
	CircleStackIcon,
	GlobeAmericasIcon,
	MagnifyingGlassCircleIcon,
	UsersIcon,
	ArrowTrendingUpIcon,
	WrenchScrewdriverIcon,
	ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline'

import chromeLogo from '../media/chrome-logo.png'

import { Dialog, Transition } from '@headlessui/react'

import { getCurrentUser } from '../services/authService'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
	const location = useLocation()

	const user = getCurrentUser()

	const navigation = [
		{
			name: 'Analytics',
			href: '/analytics',
			icon: ArrowTrendingUpIcon,
			current: location.pathname === '/analytics',
		},
		{
			name: 'Users',
			href: '/admin/users',
			icon: UsersIcon,
			current: location.pathname === '/admin/users',
			visible: user?.isAdmin,
		},
		{
			name: 'Searches',
			href: '/admin/searches',
			icon: MagnifyingGlassCircleIcon,
			current: location.pathname === '/admin/searches',
			visible: user?.isAdmin,
		},
		{
			name: 'Search Settings',
			href: '/admin/searchsettings',
			icon: WrenchScrewdriverIcon,
			current: location.pathname === '/admin/searchsettings',
			visible: user?.isAdmin,
		},
		{
			name: 'Loadboard',
			href: '/loadboard',
			icon: CircleStackIcon,
			current: location.pathname === '/loadboard',
		},
		{
			name: 'Settings',
			href: '/settings',
			icon: Cog6ToothIcon,
			current: location.pathname === '/settings',
		},
		{
			name: 'Contact Us',
			href: 'https://t.me/loadfetcher_support',
			icon: ChatBubbleLeftEllipsisIcon,
			current: false,
			target: '_blank',
		},
		{
			name: 'Install Chrome Extension',
			href: 'https://chrome.google.com/webstore/detail/relay-auto-refresher-post/ihcgicdogclbieclnldfjmlglkolankb',
			icon: GlobeAmericasIcon,
			current: false,
			target: '_blank',
		},
	].filter(item => item.visible !== false)

	const handleLogout = async () => {
		try {
			await axios.post('/api/user/logout-session')

			localStorage.removeItem('jwtToken')
			localStorage.removeItem('email')

			window.location.href = '/'
		} catch (error) {
			console.log('error', error)
			localStorage.removeItem('jwtToken')
			localStorage.removeItem('email')

			window.location.href = '/'
		}
	}

	return (
		<>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					onClose={() => setSidebarOpen(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-900/80" />
					</Transition.Child>
					<div className="fixed inset-0 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
										<button
											type="button"
											className="-m-2.5 p-2.5"
											onClick={() => setSidebarOpen(!sidebarOpen)}
										>
											<span className="sr-only">Close sidebar</span>
											<XMarkIcon
												className="h-6 w-6 text-white"
												aria-hidden="true"
											/>
										</button>
									</div>
								</Transition.Child>
								<div
									style={{ backgroundColor: '#0D1117' }}
									className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 text-white px-6 ring-1 ring-white/10"
								>
									<div className="h-16 my-8">
										<p className="font-bold mb-1">Hi, {user?.name}</p>
										<p className="text-sky-500 font-light italic tracking-wide">
											{user?.email}
										</p>
									</div>

									<nav className="flex flex-1 flex-col">
										<ul role="list" className="flex flex-1 flex-col gap-y-7">
											<li>
												<ul role="list" className="-mx-2 space-y-1">
													{navigation.map(item => (
														<li key={item.name}>
															<a
																href={item.href}
																target={item.target || '_self'}
																className={classNames(
																	item.current
																		? 'text-white bg-gray-800'
																		: 'text-gray-400 hover:text-white hover:bg-gray-800',
																	'mb-4 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																)}
															>
																{item.name === 'Install Chrome Extension' ? (
																	<img
																		src={chromeLogo}
																		alt="Chrome Extension"
																		className="h-6 w-6"
																	/>
																) : (
																	<item.icon className="h-6 w-6 text-sky-500" />
																)}
																{item.name}
															</a>
														</li>
													))}
												</ul>
											</li>

											<li className="-mx-6 mt-auto cursor-pointer">
												<a
													onClick={handleLogout}
													className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white"
												>
													<span aria-hidden="true">Log out</span>
													<ArrowRightOnRectangleIcon
														className="h-6 w-6 text-white"
														aria-hidden="true"
													/>
												</a>
											</li>
										</ul>
									</nav>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	)
}

export default Sidebar
