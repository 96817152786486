import React, { useRef, useEffect } from 'react'

import {
	Chart,
	LineController,
	LineElement,
	Filler,
	PointElement,
	LinearScale,
	TimeScale,
	Tooltip,
} from 'chart.js'
import 'chartjs-adapter-moment'

// Import utilities
import { tailwindConfig, formatThousands } from '../../utils'

Chart.register(
	LineController,
	LineElement,
	Filler,
	PointElement,
	LinearScale,
	TimeScale,
	Tooltip
)

function LineChart04({ data, width, height }) {
	const canvas = useRef(null)

	useEffect(() => {
		const ctx = canvas.current
		// eslint-disable-next-line no-unused-vars
		const chart = new Chart(ctx, {
			type: 'line',
			data: data,
			options: {
				chartArea: {
					backgroundColor: tailwindConfig().theme.colors.gray[50],
				},
				layout: {
					padding: {
						left: 20,
						right: 20,
					},
				},
				scales: {
					y: {
						display: false,
						beginAtZero: true,
					},
					x: {
						type: 'time',
						time: {
							parser: 'MM-DD-YYYY',
							unit: 'month',
						},
						display: false,
					},
				},
				plugins: {
					tooltip: {
						callbacks: {
							title: () => false, // Disable tooltip title
							label: context => formatThousands(context.parsed.y),
						},
					},
					legend: {
						display: false,
					},
				},
				interaction: {
					intersect: false,
					mode: 'nearest',
				},
				maintainAspectRatio: false,
			},
		})
		return () => chart.destroy()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <canvas ref={canvas} width={width} height={height}></canvas>
}

export default LineChart04
