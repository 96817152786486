import React, { useState } from 'react'
import Downshift from 'downshift'
import axios from 'axios'

import { formatCity } from './convertState'
// import {searchCity} from "../../services/httpService"

import './autocomplete.css'

const renderListItem = (
	city,
	index,
	getItemProps,
	highlightedIndex,
	// selectedItem,
	isDarkMode // Pass the isDarkMode prop to the renderListItem function
) => {
	const { city: cityName, state_id } = city
	// const isSelected = selectedItem === cityName
	const isHighlighted = highlightedIndex === index

	return (
		<li
			className={`dropdown-item ${isDarkMode ? 'dark-mode' : 'light-mode'} ${
				isHighlighted ? 'is-highlighted' : ''
			}`}
			{...getItemProps({ key: index, index, item: city })}
		>
			{`${cityName}, ${state_id}`}
		</li>
	)
}

export default function AutocompleteCityOrigin(props) {
	const { handleOriginChange, style, className, name, origin, isDarkMode } =
		props

	const [cities, setCities] = useState([])

	function handleCityChange(selectedCity) {
		handleOriginChange(selectedCity)
	}

	const onInputValueChange = async inputValue => {
		if (inputValue?.length < 3) {
			if (inputValue?.length < 1) handleCityChange(null)

			return setCities([])
		}

		const mapboxToken =
			'pk.eyJ1IjoibG9hZGZldGNoZXIiLCJhIjoiY2xwbnA3ZGkxMGhmczJqb3ZnMmd4cXBhbCJ9.Z1fDlbKoR2zzxFSF3nQ8Rw'
		const cleanStr = inputValue?.replace(/[^a-zA-Z ]/g, '')
		const input = encodeURIComponent(cleanStr)

		const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${mapboxToken}&cachebuster=1627451746100&autocomplete=true&country=us&types=place&limit=15`

		try {
			const response = await axios.get(url)
			const finalResult = formatCity(response.data.features)

			setCities(finalResult)
		} catch (error) {
			console.error('Error fetching suggestions:', error)
		}
	}

	return (
		<Downshift
			selectedItem={origin}
			onInputValueChange={onInputValueChange}
			onChange={handleCityChange}
			itemToString={item =>
				item && item.city && item.state_id
					? `${item.city}, ${item.state_id}`
					: ''
			}
		>
			{({
				getInputProps,
				getItemProps,
				getMenuProps,
				isOpen,
				highlightedIndex,
				selectedItem,
			}) => (
				<div className="autocomplete-container">
					<input {...getInputProps({ className, name, style })} />

					<ul
						className={`dropdown-menu ${
							isDarkMode ? 'dark-mode' : 'light-mode'
						}`}
						{...getMenuProps()}
					>
						{isOpen &&
							cities.map((city, index) =>
								renderListItem(
									city,
									index,
									getItemProps,
									highlightedIndex,
									selectedItem,
									isDarkMode
								)
							)}
					</ul>
				</div>
			)}
		</Downshift>
	)
}
