import React from 'react'
import dayjs from 'dayjs'

function GeneralSettings({
	handleManageBilling,
	isDarkMode,
	loading,
	invoiceData,
}) {
	let dateFormat = null
	let formattedDate = null
	let payed = false
	let canceledSubscription = false

	if (invoiceData?.payed) {
		payed = true
		dateFormat = new Date(invoiceData?.period_end)
		formattedDate = dayjs(dateFormat).format('MMMM D, YYYY')
	} else {
		payed = false
		dateFormat = new Date(invoiceData?.trialEndTime)
		formattedDate = dayjs(dateFormat).format('MMMM D, YYYY')
	}

	let paymentLabel

	if (!payed) {
		paymentLabel = 'Trial End'
	} else if (payed && invoiceData?.canceledSubscription) {
		paymentLabel = 'Plan Expires'
		canceledSubscription = true
	} else if (payed && !invoiceData?.canceledSubscription) {
		paymentLabel = 'Next Payment'
	}

	const textStyle = { color: isDarkMode ? 'white' : 'black' }

	const paidAmount = invoiceData?.amount / 100

	const isYearly = invoiceData?.subscription?.type === 'yearly'
	const isMonthly = invoiceData?.subscription?.type === 'monthly'
	const subType = isYearly ? 'Yearly' : isMonthly ? 'Monthly' : ''

	const isTrial = invoiceData?.subscription?.isTrial

	const subPlan = isTrial ? 'Trial' : 'Full'

	return (
		<div>
			{loading ? (
				<h1
					style={{
						textAlign: 'center',
						fontSize: '24px',
						fontWeight: 'normal',
						color: isDarkMode ? 'white' : 'black',
					}}
				>
					Loading...
				</h1>
			) : (
				<div
					className="rounded-md p-6 text-white shadow-lg max-w-lg mx-auto"
					style={{ maxWidth: '800px' }}
				>
					{/* Membership Information Card */}
					<h2 style={textStyle} className="text-lg font-semibold mb-4">
						Your Membership
					</h2>
					<div className="flex justify-between mb-2">
						<p style={textStyle} className="font-semibold">
							{canceledSubscription ? 'Cancelled' : 'Active'}{' '}
							{!payed ? 'Trial' : 'Subscription'}
						</p>
						<p style={textStyle} className="font-semibold">
							{subType} Payment
						</p>
						<p style={textStyle} className="font-semibold">
							{paymentLabel}
						</p>
					</div>
					<div className="flex justify-between mb-4 ml-2">
						<p style={textStyle} className="text-sm">
							{subPlan} Plan
						</p>
						<p style={textStyle} className="text-sm">
							{payed ? `$${paidAmount?.toFixed(2)}` : '$0.00'}
						</p>
						<p style={textStyle} className="text-sm">
							{formattedDate}
						</p>
					</div>

					<button
						onClick={handleManageBilling}
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					>
						Manage Billing
					</button>
				</div>
			)}{' '}
		</div>
	)
}

export default GeneralSettings
