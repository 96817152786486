import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import axios from 'axios'

import { baseURL } from '../../config'

function UsersList({ isDarkMode }) {
	const [searchTerm, setSearchTerm] = useState('')
	const [users, setUsers] = useState(null)
	const [loading, setLoading] = useState(false)

	const handleSearch = async () => {
		try {
			setLoading(true)
			const res = await axios.get(`${baseURL}/api/admin/user?id=${searchTerm}`)

			setUsers(res.data.user)
			setLoading(false)
		} catch (error) {
			console.log('error', error)
			setLoading(false)
		}
	}

	const fetchUsers = async () => {
		try {
			setLoading(true)

			const userToken = localStorage.getItem('token')

			const res = await axios.get(`${baseURL}/api/admin/users`, {
				headers: {
					authorization: userToken,
				},
			})
			setUsers(res.data)
			setLoading(false)
		} catch (error) {
			console.log('error', error)
			setLoading(false)
		}
	}

	const updateFreeTrial = async userId => {
		try {
			const confirm = window.confirm('Are you sure to extend the free trial?')

			if (confirm) {
				const res = await axios.get(
					`${baseURL}/api/admin/toggletrial/${userId}`
				)
			}
		} catch (err) {
			console.log(err)
			alert('Error extending free trial')
		}
	}

	useEffect(() => {
		if (searchTerm === '') {
			fetchUsers()
		}
	}, [searchTerm])

	useEffect(() => {
		fetchUsers()
	}, [])

	const cardStyle = isDarkMode
		? 'bg-gray-800 text-white hover:bg-gray-700'
		: 'bg-white text-black hover:bg-gray-100'

	function normalizeCompanyName(name) {
		if (!name) return 'Unknown'

		return name
			?.split('_')
			?.map(word => word.toUpperCase())
			?.join(' ')
	}

	const sortDevicesByLastCheckIn = devices => {
		return [...devices].sort(
			(a, b) => new Date(b.lastCheckIn) - new Date(a.lastCheckIn)
		)
	}

	return (
		<>
			<div className={`${isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}`}>
				<h1 className={`text-center text-2xl font-bold p-4`}>
					Admin Panel, {users?.length} users
				</h1>

				<div className="flex justify-center items-center mb-4">
					<form
						onSubmit={e => {
							e.preventDefault()
							handleSearch()
						}}
						className="flex justify-center items-center mb-4"
					>
						<input
							type="text"
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
							className="p-2 rounded-md"
							placeholder="Search users..."
						/>
						<button
							type="submit"
							className="bg-blue-500 text-white px-4 py-2 ml-2 rounded"
						>
							Search
						</button>
					</form>
				</div>

				{loading ? (
					<div className="text-center text-xl font-bold">Loading...</div>
				) : users?.length > 0 ? (
					<div className="grid grid-cols-3 gap-4 p-4">
						{users &&
							users.map(user => (
								<div
									key={user.id}
									className={`${cardStyle} p-4 rounded-md shadow-md transition duration-300 ease-in-out`}
								>
									<h2 className="text-xl font-bold mb-2">{user.name}</h2>
									<p>{user.email}</p>
									<p>
										Last Login:{' '}
										<span className="italic text-gray-300 text-md">
											{dayjs(user.lastLoggedAt).format('hh:mm A MMM DD YYYY')}
										</span>
									</p>

									<p>
										Resgistered:{' '}
										<span className="italic text-gray-300 text-md">
											{dayjs(user.createdAt).format('hh:mm A MMM DD YYYY')}
										</span>
									</p>

									<p>
										Is Free Trial:{' '}
										<span className="italic text-gray-300 text-md">
											{user.is_trial ? 'Yes' : 'No'}
										</span>
									</p>

									<p>
										Trial End:{' '}
										<span className="italic text-gray-300 text-md">
											{dayjs(user.trial_end).format('hh:mm A MMM DD YYYY')}
										</span>
									</p>

									<p>Companies:</p>
									<ul className="list-disc list-inside italic text-gray-300 text-md">
										{user?.companyNames?.map((company, index) => (
											<li key={index}>{normalizeCompanyName(company)}</li>
										))}
									</ul>

									<div className="mt-1">
										<h3 className="text-lg font-semibold">
											Devices ({user?.devices?.length || 0})
										</h3>
										{user?.devices?.length > 0 ? (
											<div className="space-y-3">
												{sortDevicesByLastCheckIn(user?.devices)?.map(
													(device, index) => (
														<div
															key={index}
															className="bg-gray-800 p-4 rounded-lg shadow"
														>
															<h6 className="text-lg font-semibold">
																{normalizeCompanyName(device?.companyName) ||
																	'Unknown'}
															</h6>
															<div className="flex justify-between items-start">
																<div>
																	<p className="text-sm font-medium text-gray-300">
																		#{index + 1} {device.os} - {device.browser}
																	</p>
																	<p className="text-xs text-gray-400 mt-1">
																		Type: {device.deviceType || 'Unknown'}
																	</p>
																</div>
																<span className="text-xs text-gray-400">
																	{dayjs(device.lastCheckIn).fromNow()}
																</span>
															</div>
															<div className="mt-3 text-xs text-gray-400 space-y-1">
																<p>
																	<span className="font-medium">
																		First used:
																	</span>{' '}
																	{dayjs(device.firstUsed).format(
																		'MMM DD, YYYY [at] hh:mm A'
																	)}
																</p>
																<p>
																	<span className="font-medium">
																		Extension Version:
																	</span>{' '}
																	{device?.extensionVersion}
																</p>
															</div>
														</div>
													)
												)}
											</div>
										) : (
											<p className="text-sm text-gray-400 italic">
												No devices registered
											</p>
										)}
									</div>

									<button
										onClick={() => updateFreeTrial(user.id)}
										className={`${
											user.is_trial
												? 'bg-gray-400 cursor-not-allowed'
												: 'bg-blue-500 hover:bg-blue-600'
										} text-white px-4 py-2 mt-4 rounded transition duration-300 ease-in-out`}
										disabled={user.is_trial}
									>
										Extend free trial
									</button>
								</div>
							))}
					</div>
				) : (
					<div className="text-center text-xl font-bold">No users found</div>
				)}
			</div>
		</>
	)
}

export default UsersList
