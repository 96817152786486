import React, { useState, useEffect } from 'react'

import { getCurrentUser } from '../../../../services/authService'
import { getLoadboards } from '../../../../services/httpService'

import SettingsLoadBoard from './SettingsLoadboard'
import AddNewScraper from './AddNewScraperModal'

import './scraper.css'

export default function Settings({ isDarkMode }) {
	const [loading, setLoading] = useState(false)
	const [loadboards, setLoadboards] = useState([])
	const [state, setState] = useState({
		userId: null,
		loadboard: null,
		email: null,
		password: null,
		typeChanged: false,
	})

	const getUser = async () => {
		setLoading(true)

		const { id } = await getCurrentUser()
		setState(s => {
			return {
				...s,
				userId: id,
			}
		})

		const loadboards = await getLoadboards(id)

		setLoadboards(loadboards)
		setLoading(false)
	}

	const openModal = () => {
		document.getElementById('add_new_scraper_creds').showModal()
	}

	useEffect(() => {
		setLoading(true)
		const getUser = async () => {
			setLoading(true)

			const { id } = await getCurrentUser()
			setState(s => {
				return {
					...s,
					userId: id,
				}
			})

			const loadboards = await getLoadboards(id)

			setLoadboards(loadboards)
			setLoading(false)
		}
		getUser()

		setState(s => {
			return {
				...s,
				loadboard: null,
				email: null,
				password: null,
			}
		})
	}, [])

	return (
		<div className={isDarkMode ? 'dark-mode-class' : 'light-mode-class'}>
			<div className="flex flex-col">
				<div className="flex justify-between items-center">
					<button
						onClick={openModal}
						className="text-white my-8 ml-40 btn-sm btn-info"
					>
						+ Add New Loadboard
					</button>
					<AddNewScraper />
				</div>
				<div className="flex justify-center">
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-10">
						{!loading &&
							loadboards?.map((e, index) => {
								return (
									<div key={index}>
										<SettingsLoadBoard
											loadboardArg={e}
											getUser={getUser}
											isDarkMode={isDarkMode}
										/>
									</div>
								)
							})}
					</div>
				</div>
			</div>
		</div>
	)
}
