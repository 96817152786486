import React, { useRef, useEffect } from 'react'

import {
	Chart,
	LineController,
	LineElement,
	Filler,
	PointElement,
	LinearScale,
	TimeScale,
	Tooltip,
} from 'chart.js'
import 'chartjs-adapter-moment'

// Import utilities
import { formatThousands } from '../../utils'

Chart.register(
	LineController,
	LineElement,
	Filler,
	PointElement,
	LinearScale,
	TimeScale,
	Tooltip
)

function LineChart03({ data, width, height }) {
	const canvas = useRef(null)

	useEffect(() => {
		const ctx = canvas.current

		const chart = new Chart(ctx, {
			type: 'line',
			data: data,
			options: {
				layout: {
					padding: 20,
				},
				scales: {
					y: {
						beginAtZero: true,
						grid: {
							drawBorder: false,
						},
						ticks: {
							callback: value => formatThousands(value),
						},
					},
					x: {
						type: 'time',
						time: {
							parser: 'MM-DD-YYYY',
							unit: 'day',
							displayFormats: {
								month: 'MMM YY',
							},
						},
						grid: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							autoSkipPadding: 48,
							maxRotation: 0,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							title: () => false, // Disable tooltip title
							label: context => formatThousands(context.parsed.y),
						},
					},
				},
				interaction: {
					intersect: false,
					mode: 'nearest',
				},
				maintainAspectRatio: false,
				resizeDelay: 200,
			},
		})
		return () => chart.destroy()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <canvas ref={canvas} width={width} height={height}></canvas>
}

export default LineChart03
