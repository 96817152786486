import React from 'react'
import { Header } from './Header'
import { Hero } from './Hero'
// import { PrimaryFeatures } from './PrimaryFeatures'
import { Pricing } from './Pricing'
import { Faqs } from './Faqs'
import { Footer } from './Footer'

export default function LandingPage() {
	return (
		<div style={{ backgroundColor: '#1c2126' }}>
			<Header />
			<main>
				<Hero />
				{/* <PrimaryFeatures /> */}
				<Pricing />
				<Faqs />
				<Footer />
			</main>
		</div>
	)
}
