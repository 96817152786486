import { createContext, useContext, useState, useEffect } from 'react'

const DarkModeContext = createContext()

export const useDarkMode = () => {
	return useContext(DarkModeContext)
}

export const DarkModeProvider = ({ children }) => {
	const [isDarkMode, setIsDarkMode] = useState(() => {
		const jwtToken = localStorage.getItem('jwtToken')

		if (!jwtToken) return false

		const savedMode = localStorage.getItem('darkMode')
		return savedMode ? JSON.parse(savedMode) : false
	})

	const updateHtmlBackgroundColor = mode => {
		document.documentElement.style.backgroundColor = mode
			? '#1c2127'
			: '#f5f5f7'
	}

	const toggleDarkMode = () => {
		const newMode = !isDarkMode
		setIsDarkMode(newMode)
		localStorage.setItem('darkMode', JSON.stringify(newMode))
		updateHtmlBackgroundColor(newMode)
	}

	useEffect(() => {
		updateHtmlBackgroundColor(isDarkMode)
	}, [isDarkMode])

	return (
		<DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
			{children}
		</DarkModeContext.Provider>
	)
}
