import React from 'react'

function Spinner({ isDarkMode, similarLoads }) {
	const spinnerColor = isDarkMode ? '#f5f5f7' : '#1c2127'

	return (
		<div className={`flex justify-center items-center h-auto`}>
			<span
				className="loading loading-spinner loading-lg"
				style={{
					color: spinnerColor,
					width: similarLoads ? '45px' : '60px',
					height: similarLoads ? '45px' : '60px',
					position: 'absolute',
					top: similarLoads ? 'calc(40% - 5px)' : 'calc(60% - 5px)',
				}}
			></span>
		</div>
	)
}

export default Spinner
