import React from 'react'

function Toast({ message, error }) {
	return (
		<div className="toast toast-top toast-center">
			<div className={`alert ${error ? 'alert-error' : 'alert-success'}`}>
				<span>{message}</span>
			</div>
		</div>
	)
}

export default Toast
