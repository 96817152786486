import { useEffect, useState } from 'react'
import Axios from 'axios'

import Load from './LoadSimilar'
import Spinner from '../../Loadboard/Spinner'

// import { useDarkMode } from '../../../context/DarkModeContext'

import { queryFormatter } from '../loadboardHelpers'
import { baseURL } from '../../../config'

import './viewSimilarLoads.css'

const formStyle = {
	minWidth: '1120px',
	maxHeight: 'auto',
	overflowY: 'auto',
	top: '5%',
	position: 'absolute',
	backgroundColor: '#1c2126',
	transition: 'background-color 0.3s ease',
}

function ViewSimilarLoads({ isModalOpen, closeModal, load }) {
	// const { isDarkMode, toggleDarkMode } = useDarkMode()

	const isDarkMode = true

	const [loads, setLoads] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	useEffect(() => {
		if (isModalOpen) {
			const fetchSimilarLoads = async () => {
				setIsLoading(true)

				const response = await Axios.post(
					`${baseURL}/api/loads/similar`,
					queryFormatter(load)
				)

				setLoads(response?.data?.loads)

				setIsLoading(false)
			}

			fetchSimilarLoads()

			return () => {
				setLoads([])
			}
		}
	}, [isModalOpen, load])

	return (
		<div>
			<dialog
				onClick={handleOutsideClick}
				className={`modal ${isModalOpen ? 'modal-open' : ''}`}
			>
				<form style={formStyle} method="dialog" className="modal-box">
					<h2 className="ml-10 mb-2 font-bold text-lg text-gray-200">
						Similar Loads
					</h2>

					{isLoading ? (
						<div className="h-64">
							<Spinner isDarkMode={true} similarLoads={true} />
						</div>
					) : Array.isArray(loads) && loads?.length ? (
						loads?.map(load => <Load load={load} key={load?._id} />)
					) : (
						<div
							className={` ${
								isDarkMode ? 'text-white' : 'text-black'
							} pt-24 flex justify-center items-center text-3xl font-medium text-slate-800`}
						>
							No matching data
						</div>
					)}

					<div className="modal-action">
						<button onClick={closeModal} className="btn">
							Close
						</button>
					</div>
				</form>
			</dialog>
		</div>
	)
}

export default ViewSimilarLoads
