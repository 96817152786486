import LineChart from '../Charts/LineChart'
import { useDarkMode } from '../../../context/DarkModeContext' // Import the dark mode context

import {
	tailwindConfig,
	hexToRGB,
	percDiff,
	formatThousands,
} from '../../utils'

function AnalyticsCard01({ analytics, labels, prevAnalytics }) {
	const { isDarkMode } = useDarkMode() // Access the dark mode state

	console.log('isDarkMode', isDarkMode)
	const {
		totalSum: currSum,
		totalCount: currCount,
		totalDryVan: currDry,
		totalPowerOnly: currPO,
	} = analytics || {}

	const {
		totalSum: prevSum,
		totalCount: prevCount,
		totalDryVan: prevDry,
		totalPowerOnly: prevPO,
	} = prevAnalytics || {}

	const totalSumDiff = percDiff(currSum, prevSum)
	const sumUpOrDown = currSum > prevSum ? true : false

	const totalCountDiff = percDiff(currCount, prevCount)
	const countUpOrDown = currCount > prevCount ? true : false

	const dryVanDiff = percDiff(currDry, prevDry)
	const dryUpOrDown = currDry > prevDry ? true : false

	const powerOnlyDiff = percDiff(currPO, prevPO)
	const poUpOrDown = currPO > prevPO ? true : false

	const chartData = {
		labels: labels,
		datasets: [
			// Indigo line
			{
				label: 'Previous Sum',
				data: prevAnalytics?.prev7daysPrice,
				fill: true,
				backgroundColor: `rgba(${hexToRGB(
					isDarkMode
						? tailwindConfig().theme.colors.blue[900]
						: tailwindConfig().theme.colors.blue[500]
				)}, 0.08)`,
				borderColor: isDarkMode
					? tailwindConfig().theme.colors.indigo[900]
					: tailwindConfig().theme.colors.indigo[500],
				pointBackgroundColor: isDarkMode
					? tailwindConfig().theme.colors.indigo[900]
					: tailwindConfig().theme.colors.indigo[500],
				borderWidth: 2,
				tension: 0,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
			},
			// Gray line
			{
				label: 'Current Sum',
				data: analytics?.last7daysPrice,
				borderColor: isDarkMode
					? tailwindConfig().theme.colors.gray[700]
					: tailwindConfig().theme.colors.gray[300],
				pointBackgroundColor: isDarkMode
					? tailwindConfig().theme.colors.gray[700]
					: tailwindConfig().theme.colors.gray[300],
				fill: true,
				borderWidth: 2,
				tension: 0,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
			},
		],
	}

	return (
		<div className="flex flex-col col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
			<header className="px-5 py-4 border-b border-gray-100 flex items-center">
				<h2 className="font-semibold text-gray-600">Last 7 Days</h2>
			</header>
			<div className="px-5 py-1">
				<div className="flex flex-wrap">
					{/* Total Sum */}
					<div className="flex items-center py-2">
						<div className="mr-5">
							<div className="flex items-center">
								<div className="text-3xl font-bold text-gray-800 mr-2">
									${formatThousands(analytics?.totalSum?.toFixed(0) || 0)}
								</div>
								<div
									className={`text-sm font-medium ${
										sumUpOrDown ? 'text-green-500' : 'text-red-500'
									}`}
								>
									{sumUpOrDown ? '+' : ''}
									{totalSumDiff || 0}%
								</div>
							</div>
							<div className="text-sm text-gray-500">Total Sum</div>
						</div>
						<div
							className="hidden md:block w-px h-8 bg-gray-200 mr-5"
							aria-hidden="true"
						></div>
					</div>
					{/* Total Count */}
					<div className="flex items-center py-2">
						<div className="mr-5">
							<div className="flex items-center">
								<div className="text-3xl font-bold text-gray-800 mr-2">
									{formatThousands(analytics?.totalCount || 0)}
								</div>
								<div
									className={`text-sm font-medium ${
										countUpOrDown ? 'text-green-500' : 'text-red-500'
									} `}
								>
									{countUpOrDown ? '+' : ''}
									{totalCountDiff}%
								</div>
							</div>
							<div className="text-sm text-gray-500">Total Count</div>
						</div>
						<div
							className="hidden md:block w-px h-8 bg-gray-200 mr-5"
							aria-hidden="true"
						></div>
					</div>
					{/* Dry Van */}
					<div className="flex items-center py-2">
						<div className="mr-5">
							<div className="flex items-center">
								<div className="text-3xl font-bold text-gray-800 mr-2">
									{formatThousands(analytics?.totalDryVan || 0)}
								</div>
								<div
									className={`text-sm font-medium ${
										dryUpOrDown ? 'text-green-500' : 'text-red-500'
									} `}
								>
									{dryUpOrDown ? '+' : ''}
									{dryVanDiff}%
								</div>
							</div>
							<div className="text-sm text-gray-500">Dry Van</div>
						</div>
						<div
							className="hidden md:block w-px h-8 bg-gray-200 mr-5"
							aria-hidden="true"
						></div>
					</div>
					{/* Power Only */}
					<div className="flex items-center">
						<div>
							<div className="flex items-center">
								<div className="text-3xl font-bold text-gray-800 mr-2">
									{formatThousands(analytics?.totalPowerOnly || 0)}
								</div>
								<div
									className={`text-sm font-medium ${
										poUpOrDown ? 'text-green-500' : 'text-red-500'
									} `}
								>
									{poUpOrDown ? '+' : ''}
									{powerOnlyDiff}%
								</div>
							</div>
							<div className="text-sm text-gray-500">Power Only</div>
						</div>
					</div>
				</div>
			</div>
			{/* Chart built with Chart.js 3 */}
			<div className="grow">
				{/* Change the height attribute to adjust the chart height */}
				<LineChart data={chartData} width={800} height={300} />
			</div>
		</div>
	)
}

export default AnalyticsCard01
