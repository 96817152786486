import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

function LoginPromptExtension({ userToken, sourceExtension }) {
	const [email, setEmail] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)

	useEffect(() => {
		if (!window.opener) {
			window.location.href = '/'
		} else {
			try {
				const token = localStorage.getItem('jwtToken')
				if (token) {
					const user = jwtDecode(token)
					setEmail(user?.email)
				}
			} catch (error) {
				console.error('Error decoding token:', error)
				setError(
					'Unable to retrieve user information. Please try logging in again.'
				)
			}
		}
	}, [])

	const handleConfirmLogin = async () => {
		setIsLoading(true)
		setError(null)
		try {
			const token = localStorage.getItem('jwtToken')
			if (token) {
				const user = jwtDecode(token)

				const response = await axios.post(
					'/api/users/confirm-extension-login',
					{
						userToken: userToken || null,
					}
				)

				if (response.data.success) {
					const newToken = response.data.token

					window.opener.postMessage(
						{
							source: 'loadfetcherLogin',
							token: newToken,
							email: user.email,
						},
						'*'
					)

					setTimeout(() => {
						window.close()
					}, 1000)
				} else {
					setError('Failed to confirm extension login. Please try again.')
				}
			} else {
				setError('No user token found. Please log in again.')
			}
		} catch (error) {
			console.error('Error in handleConfirmLogin:', error)
			setError('An error occurred. Please try again.')
			localStorage.removeItem('jwtToken')
			localStorage.removeItem('email')
			window.location.href = `/account/login${
				sourceExtension ? '?source=extension&token=' + userToken : ''
			}`
		} finally {
			setIsLoading(false)
		}
	}

	const handleDifferentAccount = async () => {
		try {
			setIsLoading(true)
			setError(null)

			const token = localStorage.getItem('jwtToken')

			if (!token) {
				console.log('No token found, redirecting to login page')
			}

			const decodedToken = jwtDecode(token)

			if (!decodedToken?.deviceId) {
				console.log('No device ID found in token')
			}

			await axios.post('/api/user/logout-session', {
				deviceObjID: decodedToken.deviceId,
			})

			localStorage.removeItem('jwtToken')
			localStorage.removeItem('email')
			window.location.href = `/account/login${
				sourceExtension ? '?source=extension&token=' + userToken : ''
			}`

			setIsLoading(false)
		} catch (error) {
			if (error?.response?.status === 404) {
				console.error('Error in handleDifferentAccount:', error)

				localStorage.removeItem('jwtToken')
				localStorage.removeItem('email')
				window.location.href = `/account/login${
					sourceExtension ? '?source=extension&token=' + userToken : ''
				}`
			} else {
				setError(
					error.response?.data?.message || error.message || 'An error occurred'
				)

				localStorage.removeItem('jwtToken')
				localStorage.removeItem('email')
				window.location.href = `/account/login${
					sourceExtension ? '?source=extension&token=' + userToken : ''
				}`
			}
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="flex items-center justify-center min-h-screen bg-gray-100">
			<div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
				<h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
					Login Confirmation
				</h2>
				<div className="mb-8 text-center">
					<p className="text-gray-600">
						Do you want to log in with the account:
					</p>
					<p className="text-lg font-semibold text-gray-800 mt-2">
						{email || 'Unknown'}
					</p>
				</div>
				{error && <p className="text-red-500 text-center mb-4">{error}</p>}
				<div className="flex flex-col space-y-4">
					<button
						onClick={handleConfirmLogin}
						disabled={isLoading}
						className={`w-full py-3 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 transition duration-200 ease-in-out ${
							isLoading ? 'opacity-50 cursor-not-allowed' : ''
						}`}
					>
						{isLoading ? (
							<span className="flex items-center justify-center">
								<svg
									className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle
										className="opacity-25"
										cx="12"
										cy="12"
										r="10"
										stroke="currentColor"
										strokeWidth="4"
									></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
								Confirming...
							</span>
						) : (
							'Confirm Login'
						)}
					</button>
					<button
						onClick={handleDifferentAccount}
						disabled={isLoading}
						className={`w-full py-3 px-4 bg-gray-200 text-gray-700 font-semibold rounded-lg shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75 transition duration-200 ease-in-out ${
							isLoading ? 'opacity-50 cursor-not-allowed' : ''
						}`}
					>
						Use Different Account
					</button>
				</div>
			</div>
		</div>
	)
}

export default LoginPromptExtension
