import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfigFile from './tailwind.config'

export const tailwindConfig = () => {
	// Tailwind config
	return resolveConfig(tailwindConfigFile)
}

export const hexToRGB = h => {
	let r = 0
	let g = 0
	let b = 0
	if (h.length === 4) {
		r = `0x${h[1]}${h[1]}`
		g = `0x${h[2]}${h[2]}`
		b = `0x${h[3]}${h[3]}`
	} else if (h.length === 7) {
		r = `0x${h[1]}${h[2]}`
		g = `0x${h[3]}${h[4]}`
		b = `0x${h[5]}${h[6]}`
	}
	return `${+r},${+g},${+b}`
}

export const formatValue = value =>
	Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumSignificantDigits: 3,
		notation: 'compact',
	}).format(value)

export const formatThousands = value =>
	Intl.NumberFormat('en-US', {
		maximumSignificantDigits: 3,
		notation: 'compact',
	}).format(value)

export const percDiff = (curr, prev) => {
	const decreaseValue = curr - prev
	const result = (decreaseValue / curr) * 100

	return result?.toFixed(0)
}

export const nFormatter = (num, digits) => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value
		})
	return item
		? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
		: '0'
}
