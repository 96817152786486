import React, { useEffect, useState } from 'react'
import Axios from 'axios'

import Toast from './Toast'

import { TrashIcon } from '@heroicons/react/24/solid'

import { getCurrentUser } from '../../../services/authService'
import { deleteSearch } from '../../../services/httpService'

import { baseURL } from '../../../config'
import styles from './UserSearches.module.css'

import NotificationToggle from './NotificationToggle'

function UserSearches({ isModalOpen, closeModal, setFilters }) {
	const [searches, setSearches] = useState([])
	const [loading, setLoading] = useState(false)

	const [showToast, setShowToast] = useState(false)
	const [error, setError] = useState('')

	useEffect(() => {
		async function getSearchData() {
			setLoading(true)

			const user = await getCurrentUser()

			const data = await Axios.get(
				`${baseURL}/api/search/all?limit=${20}&skip=${0}`
			)

			setSearches(data.data.searches)

			data && setLoading(false)
		}

		if (isModalOpen) {
			getSearchData()
		}
	}, [isModalOpen])

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	const applyFilters = filters => {
		setFilters({ ...filters })
		closeModal()
	}

	const deleteSearchHandler = async id => {
		try {
			await deleteSearch(id)

			setSearches(searches => searches.filter(search => search._id !== id))
		} catch (error) {
			console.error('Failed to delete search', error)
		}
	}

	if (showToast && error) {
		closeModal()
		return (
			<>
				<Toast message={error} error={true} />
			</>
		)
	}

	return (
		<div>
			{isModalOpen && (
				<dialog
					onClick={handleOutsideClick}
					className={`modal ${isModalOpen ? 'modal-open' : ''}`}
				>
					<form
						method="dialog"
						className={` modal-box ${styles['searches-modal-box-custom']}`}
						style={{ backgroundColor: '#1c2126' }}
					>
						<h3 className="text-white mb-8 font-bold text-lg">
							Saved Searches
						</h3>
						{loading ? (
							<h3 className="my-12 text-xl text-center text-gray-200">
								Loading...
							</h3>
						) : searches.length > 0 ? (
							searches.map((search, index) => (
								<div
									key={index}
									style={{ backgroundColor: '#22272d' }}
									className="rounded-md shadow-md flex justify-between items-center border border-slate-700 p-2 my-2"
								>
									<p className="text-white flex-grow">{search.searchName}</p>

									<button
										onClick={() => applyFilters(search.filters)}
										className="text-sm mx-5 px-2 py-1 hover:text-gray-300 hover:tex-gray-100 text-white rounded transition duration-200"
									>
										Apply filters
									</button>

									<div className="border-l-2 mx-2 h-6 align-middle border-gray-700"></div>

									<NotificationToggle
										search={search}
										setShowToast={setShowToast}
										setError={setError}
									/>

									<div className="border-l-2 mx-2 h-6 align-middle border-gray-700"></div>

									<TrashIcon
										onClick={() => deleteSearchHandler(search._id)}
										className="w-6 h-6 text-red-500 cursor-pointer"
									/>
								</div>
							))
						) : (
							<h3 className="my-12 text-xl text-center text-gray-200">
								You don't have any searches
							</h3>
						)}
						<div className="modal-action">
							<button
								onClick={closeModal}
								type="button"
								className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
							>
								Close
							</button>
						</div>
					</form>
				</dialog>
			)}
		</div>
	)
}

export default UserSearches
