import { Switch } from '@headlessui/react'

export default function Toggle(props) {
	const { show, setShow } = props

	return (
		<Switch
			checked={show}
			onChange={setShow}
			style={{
				backgroundColor: show ? '#20b1a5' : '#acbac7',
				display: 'inline-flex',
				flexShrink: 0,
				height: '1.5rem',
				width: '2.75rem',
				border: '2px solid transparent',
				borderRadius: '1rem',
				cursor: 'pointer',
				transition: 'colors 0.2s ease-in-out',
			}}
		>
			<span style={{ display: 'none' }}>Use setting</span>
			<span
				aria-hidden="true"
				style={{
					transform: show ? 'translateX(1.25rem)' : 'translateX(0)',
					pointerEvents: 'none',
					display: 'inline-block',
					height: '1.25rem',
					width: '1.25rem',
					borderRadius: '1rem',
					backgroundColor: 'white',
					boxShadow: '0 0 2px rgba(0,0,0,0.1)',
					transition: 'transform 0.2s ease-in-out',
				}}
			/>
		</Switch>
	)
}
