import React, { useState } from 'react'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

// import { useDarkMode } from '../../../context/DarkModeContext'
import LoadCollapse from '../../Loadboard/LoadCollapseContent/LoadCollapse'

import {
	colorBasedOnAgeSimilarLoads,
	formatLastSeenDate,
	ArrowDirection,
	validateTimeZone,
	dateDifference,
	truncateString,
} from '../../Loadboard/loadboardHelpers'

import '../../Loadboard/load.css'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(advanced)
dayjs.tz.guess()

export default function LoadCard({ load }) {
	const [isCollapsed, setIsCollapsed] = useState(true)

	const isDarkMode = true

	const {
		pickup,
		dropOff,
		updatedAt,
		price,
		weight,
		distance,
		equipment,
		loadId,
		broker,
		createdAt,
	} = load

	const pickupCity = `${pickup.address.city}, ${pickup.address.state}`
	const puTZ = validateTimeZone(pickup?.timezone, dayjs)
	const puDate = pickup?.date_local

	const pickupTime = dayjs(puDate).tz(puTZ).format('ddd MMM DD HH:mm z')

	const deliveryCity = `${dropOff.address.city}, ${dropOff.address.state}`
	const delTZ = validateTimeZone(dropOff?.timezone, dayjs)

	const delDate = dropOff?.date_local
	const deliveryDate = dayjs(delDate).tz(delTZ).format('ddd MMM DD HH:mm z')

	const source = broker.company
	const companyName = broker.companyName

	const loadStops = load?.loads?.length || 2

	const pricePerMile = price / distance

	const createdAtDiffInMins = dayjs().diff(dayjs(createdAt), 'minute')

	const styles = isDarkMode
		? {
				backgroundColor: createdAtDiffInMins < 5 ? '#313866' : '#22272e',
				color: '#adbac7',
				borderColor: '#444c56',
		  }
		: {
				backgroundColor: createdAtDiffInMins < 5 ? '#C8FFE0' : '#ffffff',
				color: '#000000',
				borderColor: '#cccccc',
		  }

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	const lastSeenStyle = colorBasedOnAgeSimilarLoads(updatedAt)
	const lastSeenDate = formatLastSeenDate(updatedAt)
	const transitDiff = dateDifference(puDate, delDate)

	const equipmentType = equipment === 'POWER ONLY' ? 'PO' : 'VAN'

	return (
		<div
			className="px-9 py-1"
			style={{ backgroundColor: isDarkMode ? '#1c2128' : '#f5f5f7' }}
		>
			<div
				onClick={toggleCollapse}
				className={`max-w-screen-xl mx-auto border shadow-sm grid grid-cols-12 gap-4 p-3 rounded-md cursor-pointer transition-all`}
				style={styles}
			>
				<div className="col-span-2 flex items-center">
					<span className="badge badge-accent badge-md mr-2">1</span>
					<div>
						<p className="text-xs">{truncateString(pickupCity, 15)}</p>
						<p style={{ fontSize: '10px' }} className="text-gray-500">
							{pickupTime}
						</p>
					</div>
				</div>

				<div className="col-span-1 mt-3">
					<ArrowDirection load={load} />
				</div>

				<div className="col-span-2 flex items-center">
					<span className="badge badge-accent badge-md mr-2">{loadStops}</span>
					<div>
						<p className="text-xs">{truncateString(deliveryCity, 15)}</p>
						<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
							{deliveryDate}
						</p>
					</div>
				</div>

				<div className="col-span-1">
					<p style={{ ...lastSeenStyle, fontSize: '10px' }} className={``}>
						{lastSeenDate}
					</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500">
						Last seen
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-xs">{weight} lb</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
						weight
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-xs">{distance.toFixed(0)} mi</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
						{transitDiff}
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-xs">{equipmentType}</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
						equipment
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-xs">${price.toFixed(2)}</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
						${pricePerMile.toFixed(2)}/mi
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-xs">{source}</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
						Source
					</p>
				</div>

				<div className="col-span-1">
					<p className="text-xs">{truncateString(companyName, 8) || '-'}</p>
					<p style={{ fontSize: '10px' }} className="text-gray-500 text-xs">
						Broker
					</p>
				</div>
			</div>

			{/* Collapsible Content */}
			{!isCollapsed && (
				<LoadCollapse
					load={load}
					isDarkMode={isDarkMode}
					styles={styles}
					isSimilarLoads={true}
				/>
			)}
		</div>
	)
}
