import React from 'react'
import clsx from 'clsx'

const formClasses =
	'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 sm:text-sm'

const SortyByLoads = ({ isDarkMode, ...props }) => {
	const conditionalClasses = isDarkMode
		? 'bg-gray-800 text-white placeholder-gray-500'
		: 'bg-gray-50 text-gray-900 placeholder-gray-400'

	const combinedClasses = `${formClasses} ${conditionalClasses}`

	return (
		<select {...props} className={combinedClasses} style={{ width: '165px' }}>
			<option value="pickupDate">Start date (Nearest)</option>
			<option value="ppm">Price per mile (Highest)</option>
			<option value="newest">Last seen (Newest)</option>
		</select>
	)
}

export default SortyByLoads
