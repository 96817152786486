import React, { useState, useEffect } from 'react'

import { AtSymbolIcon } from '@heroicons/react/20/solid'

import {
	addLoadboardCreds,
	removeLoadboardCreds,
} from '../../../../services/httpService'

import { getCurrentUser } from '../../../../services/authService'
import './scraper.css'

const Label = ({ text }) => <div className="font-semibold text-lg">{text}</div>

const Text = ({ text, isDarkMode }) => (
	<div className={`${isDarkMode ? 'text-white' : 'text-gray-800'} text-xl`}>
		{text}
	</div>
)

const Button = ({ text, color, clickHandler }) => (
	<button
		className={`btn-sm mx-1 text-white bg-${color}-500 rounded`}
		onClick={clickHandler}
	>
		{text}
	</button>
)

function SettingsLoadboard({ loadboardArg, getUser, isDarkMode }) {
	const [text, setText] = useState('')
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = useState(true)

	const [loadboard, setLoadboard] = useState(loadboardArg)

	const [creds, setCreds] = useState({
		loadboardCreds: {
			userId: '',
			loadboard: loadboard.loadboard,
			email: loadboard.email,
			password: loadboard.password,
		},
	})
	const [state, setState] = useState({
		email: '',
		password: '',
	})

	const changeHandler = e => {
		const { name, value } = e.target
		setState(s => {
			return { ...s, [name]: value }
		})

		setCreds(s => {
			return {
				...s,
				loadboardCreds: { ...creds.loadboardCreds, [name]: value },
			}
		})
	}

	const saveHandler = async e => {
		try {
			console.log('clicked submit')
			await addLoadboardCreds(creds.loadboardCreds)

			setDisabled(true)
			setLoadboard(prevState => ({
				...prevState,
				email: creds.loadboardCreds.email,
			}))
		} catch (err) {
			setText('Something went wrong!')
			return console.log(err)
		}
	}

	const deleteHandler = async () => {
		const confirmDelete = window.confirm('Are you sure you want to delete?')

		if (confirmDelete) {
			try {
				const { id } = await getCurrentUser()
				await removeLoadboardCreds(creds.loadboardCreds.loadboard, id)

				return getUser()
			} catch (err) {
				console.log(err)
				return
			}
		}
	}

	useEffect(() => {
		setLoading(true)
		const getUser = async () => {
			setLoading(true)

			const { id } = await getCurrentUser()
			setCreds(s => {
				return {
					...s,
					loadboardCreds: { ...creds.loadboardCreds, userId: id },
				}
			})
			setLoading(false)
		}
		getUser()
	}, [])

	return (
		<div className={isDarkMode ? 'dark-mode-card' : 'light-mode-card'}>
			<div
				className={`card w-96 shadow-md ${
					isDarkMode ? 'bg-gray-800' : 'bg-gray-200'
				}  p-4`}
			>
				<div className="card-body">
					<Text text={text} isDarkMode={isDarkMode} />

					<div className="space-y-4">
						{disabled ? (
							<>
								<Label text={`${loadboard.loadboard} login`} />
								<Text isDarkMode={isDarkMode} text={loadboard.email} />
								<Label text={`${loadboard.loadboard} password`} />
								<Text isDarkMode={isDarkMode} text="**********" />
							</>
						) : (
							<>
								<Label text={`${loadboard.loadboard} login`} />
								<div className="relative">
									<input
										disabled={disabled}
										name="email"
										placeholder="your email..."
										className={`text-black border rounded-lg p-2 w-full`}
										type="email"
										onChange={changeHandler}
									/>
									<AtSymbolIcon className="icon w-6 h-6 absolute right-2 top-1/2 transform -translate-y-1/2" />
								</div>
								<Label text={`${loadboard.loadboard} password`} />
								<input
									disabled={disabled}
									name="password"
									placeholder="your password..."
									className={`text-black border rounded-lg p-2 w-full`}
									type="password"
									onChange={changeHandler}
								/>
							</>
						)}
					</div>

					<div className="card-actions justify-end mt-4 flex">
						<Button
							text={disabled ? 'Edit' : 'Cancel'}
							color={disabled ? 'blue' : 'slate'}
							clickHandler={() => setDisabled(!disabled)}
						/>
						{disabled && (
							<Button text="Delete" color="red" clickHandler={deleteHandler} />
						)}

						{!disabled && (
							<Button text="Save" color="blue" clickHandler={saveHandler} />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
export default SettingsLoadboard
